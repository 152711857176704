<!-- Start Mobile Navbar -->
<div id="offcanvas-flip" class="mobile-navbar uk-mobile-navbar" uk-offcanvas="flip: true; overlay: true">
    <div class="uk-offcanvas-bar">
        <button class="uk-offcanvas-close" type="button" uk-close></button>
        <nav class="uk-navbar-container" data-uk-scrollspy-nav="offset: 0; closest: li; scroll: true">
            <ul class="uk-navbar-nav">
                <li class="uk-active">
                   
                    <li>
                        <a routerLink="/" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="uk-active">Accueil</a>
                    </li>
               
                <li>
                    <a routerLink="/professionnels" routerLinkActive="uk-active">Professionnels</a>
                </li>
                <li>
                    <a  routerLink="/diagnostics-immobiliers-obligatoires"  routerLinkActive="uk-active">Diagnostics</a>
                </li>
                <li>
                    <a  routerLink="/faq" routerLinkActive="uk-active">FAQ</a>
                </li>
                <li>
                    <a routerLink="/contact" routerLinkActive="uk-active">Contact</a>
                </li>
                <a routerLink="/devis-gratuit" class="uk-button2 uk-button-default">&#8364; Devis gratuit</a>
                <a routerLink="/rappelez-moi"  class="uk-button2 uk-button-default2">&#9742; Rappelez moi</a>
            </ul>
        </nav>
    </div>
</div>
<!-- End Mobile Navbar -->

<!-- Start Navbar Area -->
<header class="header-area {{navClass}}" data-uk-sticky="top: 0; animation: uk-animation-slide-top;">
    <div class="uk-container">
        <div class="uk-navbar">
            <div class="logo uk-navbar-left">
                <a routerLink="/">
                    <img id="img" src="assets/image/logo.png" width=90 alt="logo">
                </a>
            </div>

            <div class="uk-navbar-toggle" id="navbar-toggle" uk-toggle="target: #offcanvas-flip">
                <span></span>
                <span></span>
                <span></span>
            </div>

            <div class="navbar uk-navbar-right">
                <nav class="uk-navbar-container" data-uk-scrollspy-nav="offset: 0; closest: li; scroll: true">
                    <ul class="uk-navbar-nav">
                        <li class="uk-active">
                            
                            <li>
                                <a routerLink="/" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="uk-active">Accueil</a>
                            </li>
                       
                        <li>
                            <a routerLink="/professionnels" routerLinkActive="uk-active">Professionnels</a>
                        </li>
                        <li>
                            <a   routerLink="/diagnostics-immobiliers-obligatoires" routerLinkActive="uk-active">Diagnostics</a>
                        </li>
                        <li>
                            <a  routerLink="/faq" routerLinkActive="uk-active">FAQ</a>
                        </li>
                        <li>
                            <a routerLink="/contact" routerLinkActive="uk-active">Contact</a>
                        </li>
                        <li>
                            <!-- <a routerLink="/team" routerLinkActive="uk-active">Team</a> -->
                        </li>
                        <li>
                            <!-- <a routerLink="/testimonials" routerLinkActive="uk-active">Testimonials</a> -->
                        </li>
                       
                       
                      
                    </ul>
                </nav>

                <div class="lang">
                    <form>
                        <div>
                            <a routerLink="/devis-gratuit" class="uk-button3 uk-button-default3">&#8364; Devis gratuit</a>

                       
                       
                       
                    </div>

                    </form>
                </div>
                <div class="lang">
                    <form>
                        <div>
                            <a routerLink="/rappelez-moi"  class="uk-button2 uk-button-default2">&#9742; Rappelez moi</a>

                       
                       
                       
                    </div>

                    </form>
                </div>
                
            </div>
        </div>
    </div>
</header>
<!-- End Navbar Area -->