<!-- Start Page Title Area -->

<section class="page-title-areaa uk-section uk-funfacts sec11">
    <div class="overlay"></div>

    <div class="uk-container">
        <div class="text-content">
            <h1 class="h1-sec11">S.A Diag</h1>
            <h5><p class="p-sec11">DIAGNOSTICS IMMOBILIERS À PARIS ET DANS TOUTE LA FRANCE</p></h5>
            <div class="button-container">
            </div>
        </div>
    </div>
</section>

<!-- End Page Title Area -->

<div class="uk-container" style="margin-top: 60px; text-align: center;">
    <div class="header-section">
        <h1 id="h11">Amiante avant travaux</h1>
        <p>En France, dans le cadre de travaux de rénovation ou de démolition, il existe des obligations légales concernant le diagnostic amiante. Ces obligations sont mises en place pour assurer la sécurité des travailleurs et éviter l'exposition à l'amiante, une substance cancérigène.</p>
    </div>

    <div class="bar"></div>

    
</div>


<!-- Start Project Details Area -->
<section class="project-details-area uk-project-details uk-section"> 
<div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s div-sec9">
    <div class="uk-container" style="margin: auto;">

           
    <div  style="margin-left: 40px;" >
        <div class="faq-question" (click)="toggleAnswer('1')" >
            <span>{{ isOpen['1'] ? '-' : '+' }}</span>
                                                                                                                                                                                                                                                                                                                                                                 
            
            Établissement du Dossier Technique Amiante (DTA)

        </div>
        <div class="faq-answer" *ngIf="isOpen['1']">
            <p>Si le repérage amiante avant travaux révèle la présence d’amiante, un Dossier Technique Amiante (DTA) doit être établi. Ce dossier contient des informations sur la localisation, la nature et l’état de conservation des matériaux contenant de l’amiante dans le bâtiment.</p>
            
        </div>

<div style="margin-top: 40px;">

        <div class="faq-question" (click)="toggleAnswer('2')">
            <span>{{ isOpen['2'] ? '-' : '+' }}</span>
                                                                                                                                                                                                                                                                                                                                                                 
            
            Information des entreprises et travailleurs

        </div>
        <div class="faq-answer" *ngIf="isOpen['2']">
            <p>Avant le démarrage des travaux, l’employeur ou le maître d’ouvrage doit informer les entreprises intervenantes sur la présence d’amiante et leur transmettre les informations contenues dans le DTA. Les travailleurs doivent également être informés des risques et des mesures de prévention.</p>
            
        </div>
        </div>


        <div style="margin-top: 40px; margin-bottom: 10px;">

            <div class="faq-question" (click)="toggleAnswer('3')">
                <span>{{ isOpen['3'] ? '-' : '+' }}</span>
                                                                                                                                                                                                                                                                                                                                                                     
                
                Mesures de prévention
    
            </div>
            <div class="faq-answer" *ngIf="isOpen['3']">
                <p>Des mesures de prévention spécifiques doivent être mises en place pour éviter l’exposition à l’amiante pendant les travaux. Cela peut inclure l’utilisation d’équipements de protection individuelle (EPI), la mise en place de zones de confinement, la ventilation, et d’autres mesures de contrôle de l’amiante.</p>
                
            </div>
            </div>

            <div style="margin-top: 40px; margin-bottom: 10px;">

                <div class="faq-question" (click)="toggleAnswer('4')">
                    <span>{{ isOpen['4'] ? '-' : '+' }}</span>
                                                                                                                                                                                                                                                                                                                                                                         
                    
                    Contrôle et suivi
        
                </div>
                <div class="faq-answer" *ngIf="isOpen['4']">
                    <p>Un suivi et un contrôle réguliers doivent être effectués pendant toute la durée des travaux pour s’assurer du respect des mesures de prévention et de la sécurité des travailleurs.

                        Il est essentiel de respecter ces obligations légales pour garantir la sécurité des personnes travaillant sur le chantier et éviter les risques liés à l’amiante. Les non-conformités aux règlements en matière d’amiante peuvent entraîner des sanctions légales et des conséquences graves pour la santé.</p>
                    
                </div>
                </div>
                <div style="margin-top: 40px; margin-bottom: 10px;">

                    <div class="faq-question" (click)="toggleAnswer('5')">
                        <span>{{ isOpen['5'] ? '-' : '+' }}</span>
                                                                                                                                                                                                                                                                                                                                                                             
                        
                        Repérage amiante avant travaux
            
                    </div>
                    <div class="faq-answer" *ngIf="isOpen['5']">
                        <p>Avant d’engager des travaux de rénovation ou de démolition, il est obligatoire de faire réaliser un repérage amiante avant travaux (RAT) par un professionnel certifié. Ce repérage vise à identifier la présence d’amiante dans les matériaux du bâtiment qui seront manipulés ou altérés pendant les travaux.</p>
                        
                    </div>
                    </div>
    </div>

   


</div>    
    <img    src="/assets/image/amiante-aatt.webp" alt="image" class="toggle-image" style="min-height: 300px;">

</div>

     
</section>
<!-- End Project Details Area -->

