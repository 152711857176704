<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title" style="height: 160px;">
    <div class="uk-container">
        <h1>Une question ?</h1>
        <ul>
           
        </ul>
    </div>
</section>


<!-- Start Project Area -->
<section id="project" class="project-area uk-project uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="form-container">
              
                
        
                <form class="contact-form" [formGroup]="Formrappel" (ngSubmit)="onSubmit()">
                    <img src="/assets/image/logo.png" id="img">
                    <h2>Une question ?</h2>
                    <div class="alert-box">
                    
                        <span>Si vous avez une question, remplissez ce formulaire, et nous vous répondrons rapidement.</span>
                    </div>
                    
                   
              
                        <label for="projet">Quelle est votre projet</label>
                        <select id="projet" formControlName="projet" required>
                          <option value="" disabled selected></option>
                          <option value="Location">Location</option>
                          <option value="Vente">Vente</option>
                          <option value="Audit énergétique">Audit énergétique</option>
                          <option value="Parties communes">Parties communes</option>
                          <option value="Demande particulière">Demande particulière</option>
                        </select>
                        <div *ngIf="formControls.projet.invalid && (formControls.projet.dirty || formControls.projet.touched)">
                          <small *ngIf="formControls.projet.errors?.['required']">Projet est requis.</small>
                        </div>
              
                        <label for="type-bien">Quel est le type du bien ?</label>
                        <select id="type-bien" formControlName="typeBien" required>
                          <option value="" disabled selected></option>
                          <option value="Appartement">Appartement</option>
                          <option value="Maison">Maison</option>
                          <option value="Local commercial">Local commercial</option>
                          <option value="Local industriel">Local industriel</option>
                          <option value="Parking">Parking</option>
                          <option value="Cave">Cave</option>
                          <option value="Terrain">Terrain</option>
                        </select>
                        <div *ngIf="formControls.typeBien.invalid && (formControls.typeBien.dirty || formControls.typeBien.touched)">
                          <small *ngIf="formControls.typeBien.errors?.['required']">Type de bien est requis.</small>
                        </div>
              
                        <label for="message">
                            Votre question</label>
                        <textarea id="message" formControlName="message" rows="4" placeholder="Saisir votre question" required></textarea>
                        <div *ngIf="formControls.message.invalid && (formControls.message.dirty || formControls.message.touched)">
                          <small *ngIf="formControls.message.errors?.['required']">
                            Votre question est requis.</small>
                        </div>
              
                        <h3>Vos coordonnées</h3>
                        <div id="cordonee">
                          <div class="form-row">
                            <div class="input-group">
                              <label for="prenom">Prénom</label>
                              <input type="text" id="prenom" formControlName="prenom" placeholder="Saisir votre prénom" required>
                              <div *ngIf="formControls.prenom.invalid && (formControls.prenom.dirty || formControls.prenom.touched)">
                                <small *ngIf="formControls.prenom.errors?.['required']">Prénom est requis.</small>
                              </div>
                            </div>
              
                            <div class="input-group">
                              <label for="nom">Nom</label>
                              <input type="text" id="nom" formControlName="nom" placeholder="Saisir votre nom" required>
                              <div *ngIf="formControls.nom.invalid && (formControls.nom.dirty || formControls.nom.touched)">
                                <small *ngIf="formControls.nom.errors?.['required']">Nom est requis.</small>
                              </div>
                            </div>
                          </div>
              
                          <div class="form-row">
                          
              
                            <div class="input-group">
                              <label for="telephone">Téléphone</label>
                              <input type="number" id="telephone" formControlName="telephone" placeholder="Saisir votre téléphone" required>
                              <div *ngIf="formControls.telephone.invalid && (formControls.telephone.dirty || formControls.telephone.touched)">
                                <small *ngIf="formControls.telephone.errors?.['required']">Téléphone est requis.</small>
                              </div>
                            </div>
                          </div>
                        </div>
              
                        <a [ngClass]="{'disabled-link': Formrappel.invalid}" id="env" (click)="sendMail()" class="uk-button2 uk-button-default">Envoyer</a>
                    </form>
            </div>
        </div>
    </div>
</section>
