<!-- Start Page Title Area -->
<section class="page-title-areaa uk-page-title">
    <div class="uk-container">
        <h1 class="h1-faq">FAQ</h1>
        <ul>
            <h5> <li class="h1-li">Toutes les réponses à vos questions sur les diagnostics immobiliers</li></h5>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area uk-services-details uk-section">
    <div class="uk-container">
        <article class="uk-article services-details">
            <div class="uk-grid uk-flex">
                <div class="inner uk-width-expand">

                    <div class="services-details-desc" >
                        <h3 id="diagOb">Diagnostics obligatoires </h3>



                        <div class="faq-container">
                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('1')">
                                    <span>{{ isOpen['erp'] ? '-' : '+' }}</span>
                                    Quels sont les diagnostics obligatoires pour la vente d'un bien immobilier ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['1']">
                                    <p>Les diagnostics obligatoires pour la vente d'un bien immobilier en France sont :

                                        Le diagnostic de performance énergétique (DPE) qui permet d'évaluer la consommation énergétique du bâtiment et les émissions de gaz à effet de serre. Il doit être remis à l'acquéreur avant la signature de l'acte de vente.
                                        L'Etat des risques et pollutions (ERP) qui informe sur les risques naturels et technologiques auxquels est exposé le bien immobilier. Il doit être remis à l'acquéreur avant la signature de l'acte de vente.
                                        Le diagnostic plomb, qui vise à détecter la présence de plomb dans les peintures d'un logement construit avant 1949. Il doit être remis à l'acquéreur si le bâtiment a été construit avant 1949.
                                        Le diagnostic termites qui vise à détecter la présence d'insectes xylophages (termites) dans le logement. Il doit être remis à l'acquéreur si le bien se trouve dans une zone visé par un arrêté préfectoral.
                                        L'état des installations intérieures de gaz et d'électricité qui vise à vérifier l'état des installations de gaz et d'électricité qui ont plus de 15 ans. Il doit être remis à l'acquéreur si les installations ont plus de 15 ans.
                                        Le mesurage Loi Carrez qui permet de connaitre la superficie de vente d'un logement en copropriété. Il doit être remis à l'acquéreur avant la signature de l'acte de vente.
                                        Le diagnostic d'amiante, qui vise à détecter la présence d'amiante dans le bâtiment. Il doit être remis à l'acquéreur si le bâtiment a été construit avant le 1er juillet 1997.
                                        Le diagnostic d'assainissement qui vise à vérifier le bon fonctionnement et la conformité des installations d'assainissement. Il doit être remis à l'acquéreur si le bien est concerné.
                                        Il est important de noter que ces diagnostics doivent être réalisés par des professionnels certifiés et doivent être valides à la date de la signature de l'acte de vente. Il est important de vérifier les informations avec un professionnel en immobilier ou un diagnostiqueur certifié pour vous assurer que les diagnostics sont à jour et valides.</p>
                                </div>
                            </div>
                       
                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('2')">
                                    <span>{{ isOpen['plomb'] ? '-' : '+' }}</span>
                                    Quels sont les diagnostics obligatoires pour la location d'un bien immobilier ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['2']">
                                    <p>les diagnostics obligatoires pour la location d'un bien immobilier en France sont :

                                        Le diagnostic de performance énergétique (DPE) qui permet d'évaluer la consommation énergétique du bâtiment et les émissions de gaz à effet de serre. Il doit être remis à l'acquéreur avant la signature du bail.
                                        L'Etat des risques et pollutions (ERP) qui informe sur les risques naturels et technologiques auxquels est exposé le bien immobilier. Il doit être remis à l'acquéreur avant la signature du bail.
                                        L'état des installations intérieures de gaz et d'électricité qui vise à vérifier l'état des installations de gaz et d'électricité qui ont plus de 15 ans. Il doit être remis à l'acquéreur si les installations ont plus de 15 ans.
                                        Le mesurage Loi Boutin qui permet de connaître la superficie de location d'un logement. Il doit être remis à l'acquéreur avant la signature du bail.
                                        Le diagnostic amiante, qui vise à détecter la présence d'amiante dans le bien. Il doit être remis à l'acquéreur si le bâtiment a été construit avant le 1er juillet 1997.
                                        Le diagnostic plomb, qui vise à détecter la présence de plomb dans les peintures d'un logement construit avant 1949. Il doit être remis à l'acquéreur si le bâtiment a été construit avant 1949.
                                        Il est important de noter que ces diagnostics doivent être réalisés par des professionnels certifiés et doivent être valides à la date de la signature du bail. Il est important de vérifier les informations avec un professionnel en immobilier ou un diagnostiqueur certifié pour vous assurer que les diagnostics sont à jour et valides.</p>
                                </div>
                            </div>
                       
                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('3')">
                                    <span>{{ isOpen['3'] ? '-' : '+' }}</span>
                                 
                                      Quand doivent-être réalisés les diagnostics obligatoires pour la vente d'un bien immobilier ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['3']">
                                    <p>Afin de publier l’annonce de vente du bien, le DPE et le diagnostic Loi Carrez (surface) doivent être valides. Les diagnostics obligatoires pour la vente d'un bien immobilier doivent être réalisés avant la mise en vente du bien. Cela signifie qu'ils doivent être réalisés et remis à l’acheteur avant la signature de l'acte authentique chez le notaire. Il est important de vérifier avec un professionnel en immobilier ou un diagnostiqueur certifié pour vous assurer que les diagnostics sont valides et à jour.</p>
                                </div>
                            </div>
                       


                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('4')">
                                    <span>{{ isOpen['4'] ? '-' : '+' }}</span>
                                   
                                  Quand doivent-être réalisés les diagnostics obligatoires pour la location d'un bien immobilier ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['4']">
                                    <p>les diagnostics obligatoires pour la location d'un bien immobilier en France sont :

                                        Afin de publier l’annonce de location du bien, le DPE et le diagnostique Loi Boutin (surface) doivent être valides. Les diagnostics obligatoires pour la location d'un bien immobilier doivent être réalisés avant la signature du bail. Cela signifie qu'ils doivent être réalisés et remis à l'acquéreur avant la signature du bail. Il est important de vérifier avec un professionnel en immobilier ou un diagnostiqueur certifié pour vous assurer que les diagnostics sont valides et à jour.</p>
                                </div>
                            </div>
                       
                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('5')">
                                    <span>{{ isOpen['5'] ? '-' : '+' }}</span>
                                 
                                    Qui est habilité à réaliser les diagnostics obligatoires pour la vente ou la location d'un bien immobilier ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['5']">
                                    <p>Les diagnostics obligatoires pour la vente ou la location d'un bien immobilier doivent être réalisés par des professionnels certifiés et habilités. Cela signifie que les diagnostics doivent être réalisés par des personnes qui ont suivi une formation spécifique et qui ont obtenu une certification reconnue par les autorités compétentes.

                                        Il existe différents organismes qui délivrent ces certifications, tels que :
                                       
                                        l'Association française de normalisation (AFNOR) pour le DPE
                                        le CSTB pour les diagnostics gaz et électricité
                                        les certifications reconnues par le ministère du logement pour les diagnostics amiante, plomb et ERP.
                                        Il est important de vérifier que le diagnostiqueur est certifié et à jour de sa certification pour s'assurer que les diagnostics sont valides et conformes à la réglementation en vigueur.</p>
                                </div>
                            </div>


                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('6')">
                                    <span>{{ isOpen['6'] ? '-' : '+' }}</span>
                                 
                                    Combien coûtent les diagnostics obligatoires pour la vente ou la location d'un bien immobilier ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['6']">
                                    <p>Le coût des diagnostics obligatoires pour la vente ou la location d'un bien immobilier varie en fonction de plusieurs facteurs tels que la taille et l'âge du bien, la localisation, les équipements et les matériaux utilisés, et le professionnel qui réalise les diagnostics.

                                        En général, on peut estimer que le coût total des diagnostics pour une vente ou une location d'un appartement oscille entre 200 et 600 €, tout dépendant de la taille et de l'état du bien. Il est important de noter que ces coûts sont à la charge du propriétaire du bien.
                                       
                                        Il est toujours préférable de demander un devis auprès de plusieurs diagnostiqueurs pour comparer les tarifs et choisir celui qui convient le mieux à vos besoins.</p>
                                </div>
                            </div>


                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('7')">
                                    <span>{{ isOpen['7'] ? '-' : '+' }}</span>
                                 
                                    Les diagnostics immobiliers sont-ils obligatoires pour des locations de courte durée (ex: Airbnb ou saisonnières) ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['7']">
                                    <p>Les diagnostics immobiliers ne sont pas obligatoires pour des locations de courte durée, comme les locations saisonnières ou les locations à travers des plateformes comme Airbnb. Cependant, il est important de noter que les propriétaires de biens immobiliers sont responsables de la sécurité de leurs locataires et de l'état des lieux, et il est donc fortement recommandé de réaliser les diagnostics obligatoires pour la location d'un bien immobilier.

                                        Il est également important de vérifier les règles et les réglementations locales, car certaines communes peuvent avoir des exigences spécifiques en matière de location de courte durée, y compris les exigences de diagnostics immobiliers. Il est donc important de se renseigner auprès des autorités compétentes avant de mettre un bien en location de courte durée.</p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('8')">
                                    <span>{{ isOpen['8'] ? '-' : '+' }}</span>
                                 
                                   
                                      Les diagnostics immobiliers sont-ils obligatoires pour des locations meublées ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['8']">
                                    <p>Les diagnostics immobiliers obligatoires pour la location meublée sont identiques à la location non meublée. Ces diagnostics incluent le diagnostic de performance énergétique (DPE), le diagnostic électrique et le diagnostic gaz (si le bien est raccordé au gaz), le diagnostic ERP, le diagnostic amiante et le diagnostic plomb, le diagnostic loi Boutin (surface). Il est important pour les propriétaires de se conformer aux règles et réglementations en vigueur pour garantir la sécurité et le confort des locataires.</p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('9')">
                                    <span>{{ isOpen['9'] ? '-' : '+' }}</span>
                                                                   
                                     
                                      Qu'est-ce qu'un diagnostic de performance énergétique (DPE) ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['9']">
                                    <p>Un diagnostic de performance énergétique (DPE) est un document qui évalue la consommation énergétique d'un logement ainsi que les émissions de gaz à effet de serre liées à cette consommation. Il est valide pendant 10 ans. Il est réalisé par un diagnostiqueur immobilier certifié et permet de donner une note sur une échelle allant de A à G, A étant la meilleure performance énergétique et G étant la moins bonne. Le DPE est obligatoire pour toutes les ventes et locations de biens immobiliers depuis le 1er novembre 2006. Il est utilisé pour informer les acheteurs ou les locataires des coûts d'utilisation de l'énergie de leur logement et pour inciter les propriétaires à améliorer l'efficacité énergétique de leur bien.</p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('10')">
                                    <span>{{ isOpen['10'] ? '-' : '+' }}</span>
                                                                   
                                     
                                    Qu'est-ce qu'un état des risques et pollutions (ERP) ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['10']">
                                    <p>L'état des risques et pollutions (ERP) est un document qui informe les acheteurs ou les locataires d'un bien immobilier des risques naturels et technologiques présents dans la zone où se situe le bien. Il est réalisé par les services de l'Etat et est valable 6 mois. Il est obligatoire pour toutes les ventes de biens immobiliers depuis le 1er juillet 1997. Il permet aux futurs propriétaires d'être informés des risques tels que les inondations, les risques sismiques, les risques d'exposition aux pollutions et aux risques liés à l'utilisation d'anciens sites industriels ou militaires. Il est également important pour les locataires pour comprendre les risques à vivre dans un certain endroit.</p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('11')">
                                    <span>{{ isOpen['11'] ? '-' : '+' }}</span>
                                                                   
                                     
                                    Qu'est-ce qu'un diagnostic plomb ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['11']">
                                    <p>Un diagnostic plomb est un test réalisé pour détecter la présence de plomb dans les peintures, revêtements et autres matériaux utilisés dans la construction d'un bâtiment. Le plomb est un métal lourd qui peut causer des problèmes de santé graves, notamment chez les enfants, s'il est ingéré ou inhalé. Le diagnostic plomb est obligatoire pour les logements construits avant 1949, il est réalisé par un diagnostiqueur certifié. Il permet de connaître les risques liés à la présence de plomb et de prendre les mesures nécessaires pour les éliminer. Il est valide pendant 6 ans pour la location et 1 an pour la vente, mais il est recommandé de le refaire avant toute location ou vente du bien immobilier pour s'assurer que le logement est toujours en conformité avec les normes en vigueur.</p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('11')">
                                    <span>{{ isOpen['11'] ? '-' : '+' }}</span>
                                                                   
                                     
                                    Qu'est-ce qu'un diagnostic termites ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['11']">
                                    <p>Un diagnostic termites est un diagnostic qui permet de détecter la présence de termites dans un bâtiment. Il permet de savoir si un logement est infesté par ces insectes et s'il présente des risques pour la structure du bâtiment. Il est valable 6 mois et doit être refait si l'on constate des signes d'infestation ou si le propriétaire ou locataire souhaite effectuer des travaux dans le logement. Il est obligatoire pour toute vente ou location dans certaines régions de France où le risque d'infestation est élevé.</p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('12')">
                                    <span>{{ isOpen['12'] ? '-' : '+' }}</span>
                                                                   
                                     
                                   
                                   Qu'est-ce qu'un diagnostic électricité ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['12']">
                                    <p>Un diagnostic électricité est un document qui analyse les installations électriques d'un bien immobilier afin de déterminer leur conformité aux normes en vigueur. Il vérifie l'état des appareils de protection, des circuits, des équipements électriques, des fils et des câbles, ainsi que les éventuelles anomalies. Il a pour but de s'assurer de la sécurité des occupants et de signaler les éventuels travaux à réaliser pour remédier aux défauts constatés. Sa validité est de 6 ans pour les locations et de 3 ans ou moins pour les ventes.</p>
                                </div>
                            </div>


                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('12')">
                                    <span>{{ isOpen['12'] ? '-' : '+' }}</span>
                                                                   
                                     
                                   
                                    Qu'est-ce qu'un diagnostic gaz ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['12']">
                                    <p>Un diagnostic gaz est un examen technique réalisé par un professionnel qualifié, qui vise à vérifier le bon état des équipements de gaz (chauffage, cuisinière, etc.) d'un bâtiment. Il comprend notamment une vérification de l'étanchéité des équipements, de l'absence de fuites de gaz, de la conformité des installations aux normes en vigueur, et de l'absence de risques d'incendie ou d'explosion. La validité de ce diagnostic est de 3 ans pour la vente d'un bien immobilier et de 6 ans pour la location.</p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('13')">
                                    <span>{{ isOpen['13'] ? '-' : '+' }}</span>
                                                                   
                                     
                                   
                                    Qu'est-ce qu'un diagnostic amiante ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['13']">
                                    <p>Un diagnostic amiante est un document qui permet de savoir si un bien immobilier contient de l'amiante et, le cas échéant, où se trouvent les matériaux qui en sont composés. Il est obligatoire pour tous les logements construits avant le 1er juillet 1997 et doit être réalisé par un professionnel certifié avant la vente ou la location d'un bien. La validité de ce diagnostic est illimitée si l'absence d'amiante est confirmée, sinon il est recommandé de le refaire avant chaque vente.</p>
                                </div>
                            </div>


                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('14')">
                                    <span>{{ isOpen['14'] ? '-' : '+' }}</span>
                                                                   
                                     
                                   
                                    Qu'est-ce qu'un diagnostic loi Boutin ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['14']">
                                    <p>Un diagnostic loi Boutin est un document qui mesure la surface habitable d'un logement locatif, et qui est requis pour toutes les locations de logements meublés ou non meublés, dans le but de s'assurer que le loyer est en conformité avec la loi Boutin de 2009. Il doit être effectué par un expert indépendant habilité et sa validité est illimitée. Cependant, il est conseillé de le renouveler avant chaque location pour s'assurer de la conformité du logement. En cas de travaux de rénovation, il est conseillé de réaliser un nouveau diagnostic de surface habitable pour s'assurer que les travaux n'ont pas modifié la surface habitable du logement.</p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('15')">
                                    <span>{{ isOpen['15'] ? '-' : '+' }}</span>
                                                                   
                                     
                                   
                                    Qu'est-ce qu'un diagnostic loi Carrez ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['15']">
                                    <p>Le diagnostic loi Carrez est un diagnostic immobilier qui permet de mesurer la surface privative d'un logement. Il doit être réalisé avant toute vente d'un logement en copropriété. Sa validité est illimitée si aucuns travaux de réaménagement n'ont été réalisés sur les surfaces privatives. Il est conseillé de réaliser ce diagnostic avant chaque vente car la responsabilité du vendeur est engagée sur la surface annoncée.</p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('16')">
                                    <span>{{ isOpen['16'] ? '-' : '+' }}</span>
                                                                   
                                     
                                   
                                    Qu'est-ce qu'un diagnostic assainissement ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['16']">
                                    <p>Le diagnostic d'assainissement est un contrôle technique qui est fait lors de la vente d'un bien immobilier pour s'assurer que les systèmes de traitement des eaux usées fonctionnent correctement et sont entretenus. Il a 3 objectifs principaux : s'assurer que le logement respecte les normes et les règlements, informer l'acheteur des éventuels défauts et protéger l'environnement. Depuis 2011, ce diagnostic est obligatoire pour les propriétés qui ont un système d'assainissement non collectif (comme une fosse septique), mais pas pour celles qui sont reliées au tout-à-l'égout. Cependant, certaines communes peuvent imposer ce diagnostic pour les propriétés reliées au tout-à-l'égout, il est donc important de vérifier auprès des autorités locales. La validité de ce diagnostic est généralement de 3 ans, sauf si des travaux d'assainissement ont été réalisés sur le bien immobilier dans l'intervalle, auquel cas il devra être refait.</p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('17')">
                                    <span>{{ isOpen['17'] ? '-' : '+' }}</span>
                                                                   
                                     
                                   
                                    Qu'est-ce qu'un audit énergétique ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['17']">
                                    <p>Un audit énergétique est une évaluation de la performance énergétique d'un bâtiment ou d'une installation. Il permet de détecter les sources de consommation d'énergie inutiles et de proposer des solutions pour réduire la consommation d'énergie et les coûts associés. L'audit énergétique peut inclure des mesures de consommation d'énergie, des inspections visuelles, des calculs de rendement et des recommandations pour améliorer l'efficacité énergétique. Il peut être réalisé pour des bâtiments résidentiels, commerciaux ou industriels. Il est souvent effectué par des experts certifiés et est utilisé pour améliorer la performance énergétique, réduire les coûts d'exploitation et respecter les normes environnementales.</p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('18')">
                                    <span>{{ isOpen['18'] ? '-' : '+' }}</span>
                                                                   
                                     
                                   
                                   
                                  Qu'est-ce qu'un dossier technique global (DTG) ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['18']">
                                    <p>Un dossier technique global (DTG) est un document qui rassemble l'ensemble des informations techniques et administratives relatives à un immeuble ou un ensemble immobilier. Il permet de disposer d'une vision globale sur l'état des installations, les équipements, les matériaux et les caractéristiques énergétiques de l'immeuble. Il est souvent utilisé pour les immeubles collectifs et les copropriétés, il permet de suivre l'entretien et les éventuels travaux à réaliser. Il est généralement établi par un prestataire qualifié et peut inclure différents types de diagnostics tels que les diagnostics techniques, énergétiques ou encore les diagnostics réglementaires. Il peut être mis à jour régulièrement pour prendre en compte les évolutions réglementaires, les modifications des installations ou les travaux réalisés.</p>
                                </div>
                            </div>





                            <!-- Add more FAQ items as needed -->
                        </div>
                       
                       
                       

                     
                    </div>


                    <div class="services-details-desc items">
                        <h3 id="audit">Audit énergétique </h3>



                        <div class="faq-container">
                           

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('19')">
                                    <span>{{ isOpen['19'] ? '-' : '+' }}</span>
                                                                   
                                     
                                   
                                   
                                    Pourquoi faire un audit énergétique ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['19']">
                                    <p>Un audit énergétique est un outil indispensable pour identifier les sources de gaspillage énergétique dans un bâtiment ou un logement. En évaluant la performance énergétique d'un bien immobilier, il permet de repérer les zones de consommation excessive et de proposer des solutions pour réduire les coûts énergétiques. L'audit énergétique est ainsi une étape clé pour améliorer l'efficacité énergétique d'un bâtiment ou d’un logement, réduire les émissions de gaz à effet de serre, améliorer le confort des occupants, et réduire la facture énergétique.</p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('20')">
                                    <span>{{ isOpen['20'] ? '-' : '+' }}</span>
                                                                   
                                     
                                   
                                   
                                    Quelles sont les dates clés ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['20']">
                                    <p>L'audit énergétique est devenu obligatoire pour les grandes entreprises depuis le 1er janvier 2016, et pour les PME depuis le 1er janvier 2023. Il doit être renouvelé tous les 4 ans pour les grandes entreprises, et tous les 6 ans pour les PME.</p>
                                    <p>Pour les logements d’habitation, l'audit énergétique ne concerne que la vente des maisons individuelles et les immeubles en monopropriétés. Depuis le 1er Avril 2023, l’audit énergétique est obligatoire pour les logements dont le DPE est noté F et G. Au 1er janvier 2025, l’audit énergétique est obligatoire pour les logements dont le DPE est noté E. Au 1er janvier 2034, l’audit énergétique est obligatoire pour les logements dont le DPE est noté D.</p>
                                    <p>Par ailleurs, un audit énergétique volontaire peut être réalisé à tout moment pour évaluer la performance énergétique d'un bâtiment et améliorer son efficacité énergétique.</p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('21')">
                                    <span>{{ isOpen['21'] ? '-' : '+' }}</span>
                                                                   
                                     
                                   
                                   
                                    Comment se déroule un audit énergétique ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['21']">
                                    <p>L'audit énergétique se déroule en plusieurs étapes. Tout d'abord, il est important de réaliser une analyse des données énergétiques existantes pour comprendre le fonctionnement du bâtiment ou du logement. Ensuite, il faut réaliser une visite sur site pour repérer les zones de consommation excessive et les anomalies. Enfin, il faut réaliser une évaluation des investissements nécessaires pour améliorer l'efficacité énergétique du bâtiment ou du logement. L'ensemble de ces étapes permet de proposer des solutions personnalisées pour réduire les coûts énergétiques.</p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('22')">
                                    <span>{{ isOpen['22'] ? '-' : '+' }}</span>
                                                                   
                                     
                                   
                                   
                                    Quelles actions doivent être entreprises après l'audit énergétique ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['22']">
                                    <p>Après l'audit énergétique, il est important de mettre en place les actions recommandées pour améliorer l'efficacité énergétique du bâtiment. Cela peut passer par des travaux d'isolation, de remplacement de fenêtres, d'installation d'équipements économes en énergie, etc. Il est également important de suivre régulièrement les consommations énergétiques pour vérifier l'impact des actions mises en place et ajuster si besoin.</p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('23')">
                                    <span>{{ isOpen['23'] ? '-' : '+' }}</span>
                                                                                                                                                                                                     
                                        Qu'est-ce que l'Audit Énergétique Incitatif ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['23']">
                                    <p>L'audit énergétique incitatif est une évaluation volontaire de l'efficacité énergétique de votre domicile, effectuée par un professionnel qualifié. La principale différence entre l'audit incitatif et l'audit obligatoire réside dans son caractère volontaire. Cependant, il offre d'importants avantages, notamment :</p>
                                    <p>Accès à MaPrimeRénov' : Cet audit est essentiel pour bénéficier de MaPrimeRénov', une aide gouvernementale pour la rénovation énergétique.</p>
                                    <p>Certificats d'Économies d'Énergie (CEE) : Il permet de récupérer une partie de vos dépenses en vendant des CEE.</p>
                                    <p>Optimisation des rénovations : Vous recevrez des recommandations personnalisées pour maximiser vos économies d'énergie.</p>
                                    <p>En bref, l'audit énergétique incitatif vous aide à obtenir des subventions, à améliorer l'efficacité énergétique de votre domicile, et à maximiser vos économies. C'est un investissement rentable pour l'avenir de votre maison et de votre budget.</p>
                                </div>
                            </div>





                            <!-- Add more FAQ items as needed -->
                        </div>
                       
                       
                       

                     
                    </div>



                   
                    <div class="services-details-desc items">
                        <h3 id="parCom">Parties communes </h3>



                        <div class="faq-container">
                           

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('24')">
                                    <span>{{ isOpen['24'] ? '-' : '+' }}</span>
                                                                                                                                                                         
                                   
                                     Diagnostic amiante pour les parties communes
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['24']">
                                    <p>Le <strong>diagnostic amiante pour les parties communes</strong>, dit « Dossier Technique Amiante » (DTA), est une étape cruciale pour garantir la sécurité des occupants d'un immeuble. Nous réalisons une analyse minutieuse de toutes les parties communes pour détecter la présence d'amiante. Le dossier fourni au syndic ou à la copropriété est nécessaire pour la vente de tout bien en copropriété. Il devra être envoyé au notaire.</p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('25')">
                                    <span>{{ isOpen['25'] ? '-' : '+' }}</span>
                                                                   
                                     
                                   
                                   
                                    Diagnostic plomb pour les parties communes
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['25']">
                                    <p>Le <strong>diagnostic plomb pour les parties communes</strong> permet d'identifier la présence de plomb dans les parties communes et de proposer des mesures pour protéger la santé des occupants de l'immeuble. Nous réalisons des analyses rigoureuses pour garantir la sécurité de tous les habitants.</p>
                                </div>
                            </div>


                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('26')">
                                    <span>{{ isOpen['26'] ? '-' : '+' }}</span>
                                                                   
                                     
                                   
                                   
                                    Diagnostic termites pour les parties communes
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['26']">
                                    <p>Le <strong>diagnostic termites pour les parties communes</strong> permet de repérer la présence de termites dans les parties communes et de proposer des solutions pour éliminer ces insectes nuisibles. Nous sommes à votre disposition pour réaliser un diagnostic complet et vous aider à garantir la sécurité de votre immeuble.</p>
                                </div>
                            </div>


                           





                            <!-- Add more FAQ items as needed -->
                        </div>
                       
                       
                       

                     
                    </div>



                    <div class="services-details-desc items">
                        <h3 id="ValDiagImo">Validité des diagnostics immobilier </h3>



                        <div class="faq-container">
                           

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('27')">
                                    <span>{{ isOpen['27'] ? '-' : '+' }}</span>
                                                                                                                                                                         
                                   
                                    Quelle est la validité des diagnostics obligatoires pour la vente d'un bien immobilier ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['27']">
                                    <p>La validité des diagnostics obligatoires pour la vente d'un bien immobilier varie en fonction du type de diagnostic.</p>
                                    <p>Pour le diagnostic de performance énergétique (DPE), il est valide 10 ans.</p>
                                    <p>Pour l'état des risques et pollutions (ERP), il est valide 6 mois.</p>
                                    <p>Pour le diagnostic plomb, Illimité si abscence de plomb. En cas de présence, il est valable 1 an pour la vente.</p>
                                    <p>Pour le diagnostic termites, il est valide 6 mois</p>
                                    <p>our le diagnostic électricité, il est valide 3 ans pour la vente. </p>
                                    <p>Pour le diagnostic gaz, il est valide 3 ans pour la vente.</p>
                                    <p>Pour le diagnostic amiante, la validité est illimitée si aucune présence d'amiante n'est détectée. Il est conseillé de réaliser un diagnostic amiante avant chaque vente.</p>
                                    <p>Pour le diagnostic Loi Carrez, la validité est illimitée, sauf s’il y a des travaux d'aménagement qui ont été réalisés dans le logement.</p>
                                    <p>Pour le diagnostic assainissement, il est valable 3 ans.</p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('28')">
                                    <span>{{ isOpen['28'] ? '-' : '+' }}</span>
                                                                   
                                     
                                   
                                   
                                   
                                 Quelle est la validité des diagnostics obligatoires pour la location d'un bien immobilier ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['28']">
                                    <p>La validité des diagnostics obligatoires pour la vente d'un bien immobilier varie en fonction du type de diagnostic.</p>
                                    <p>Pour le diagnostic de performance énergétique (DPE), il est valide 10 ans.
                                    </p>
                                    <p>Pour l'état des risques et pollutions (ERP), il est valide 6 mois.</p>
                                    <p>Pour le diagnostic plomb, Illimité si absence de plomb. En cas de présence, il est valable 6 ans pour la location.</p>
                                    <p>Pour le diagnostic électricité, il est valide 6 ans pour la location. </p>
                                    <p>Pour le diagnostic gaz, il est valide 6 ans pour la location.</p>
                                    <p>Pour le diagnostic amiante, la validité est illimitée si aucune présence d'amiante n'est détectée.</p>
                                    <p>Pour le diagnostic Loi Boutin, la validité est illimitée, sauf s’il y a des travaux d'aménagement qui ont été réalisés dans le logement.</p>
                                </div>
                            </div>


                     

                           





                            <!-- Add more FAQ items as needed -->
                        </div>
                       
                       
                       

                     
                    </div>


                   
                    <div class="services-details-desc items">
                        <h3 id="diagProcess">Déroulement d'un diagnostic immobilier </h3>



                        <div class="faq-container">
                           

                         

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('29')">
                                    <span>{{ isOpen['29'] ? '-' : '+' }}</span>
                                                                   
                                     
                                   
                                   
                                   
                                    Comment se déroule la réalisation d'un diagnostic de performance énergétique (DPE) ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['29']">
                                   
                                    <p>La réalisation d'un diagnostic de performance énergétique (DPE) se déroule en plusieurs étapes. Tout d'abord, un diagnostiqueur certifié se rend sur les lieux pour effectuer une visite de l'habitation. Il prend des mesures et des relevés de différents paramètres tels que la surface habitable, l'isolation, les équipements de chauffage et de production d'eau chaude, les fenêtres, etc. Il utilise ensuite ces informations pour remplir un rapport qui détaille les consommations énergétiques du bâtiment et les émissions de gaz à effet de serre associées. Il attribue également une note globale de performance énergétique (de A à G) et une étiquette énergie (de A à G) qui reflètent les performances énergétiques de l'habitation. Le DPE est valable 10 ans.</p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('30')">
                                    <span>{{ isOpen['30'] ? '-' : '+' }}</span>
                                                                   
                                                                                                                                                                           
                                     Comment se déroule la réalisation d'un état des risques et pollutions (ERP) ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['30']">
                                   
                                    <p>La réalisation d'un État des Risques et Pollutions (ERP) consiste en une analyse des risques naturels, technologiques et sanitaires auxquels un bien immobilier est exposé. Cette analyse est réalisée par un diagnostiqueur immobilier agréé et peut être effectuée à distance, sans nécessité de se déplacer sur le lieu du bien. Les informations nécessaires pour réaliser l'ERP peuvent être obtenues à partir de sources telles que les plans, les cartes de risques et les données en ligne. Une fois l'analyse réalisée, le diagnostiqueur rédige un rapport qui est remis au propriétaire du bien et inclus dans les documents de vente ou de location.</p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('31')">
                                    <span>{{ isOpen['31'] ? '-' : '+' }}</span>
                                                                                                                                                                                                                                                                                 
                                       Comment se déroule la réalisation d'un diagnostic plomb ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['31']">
                                   
                                    <p>La réalisation d'un diagnostic plomb se fait généralement par un professionnel certifié, qui se rend sur le lieu pour effectuer des mesures à l’aide d’un appareil à fluorescence X dans différentes pièces du bien immobilier. Ces mesures sont ensuite répertoriés dans le rapport de diagnostic. Le diagnostic plomb doit être réalisé par des diagnostiqueurs certifiés et agréés par les pouvoirs publics. Le diagnostiqueur réalise un état des lieux de l'ensemble des éléments pouvant contenir du plomb et les éventuelles traces de plomb. Il est important de noter que le diagnostic plomb est obligatoire pour tous les immeubles construits avant 1949. Il est donc important d'être en conformité avec les réglementations en vigueur.</p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('32')">
                                    <span>{{ isOpen['32'] ? '-' : '+' }}</span>
                                                                                                                                                                                                                                                                                 
                                    Comment se déroule la réalisation d'un diagnostic termites ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['32']">
                                   
                                    <p>Il existe plusieurs étapes pour réaliser un diagnostic termites. Tout d'abord, le diagnostic termites doit être effectué par un professionnel certifié et agréé. Ce dernier va ensuite inspecter visuellement tous les éléments du bâtiment, en particulier les parties en bois, pour détecter la présence de termites ou de traces d'infestation. Il peut utiliser des outils tels que des sondes pour vérifier l'intérieur des murs et des planchers. Il peut également utiliser des instruments électroniques pour détecter les termites. Si des termites sont détectés, il peut être nécessaire de réaliser des prélèvements et d'utiliser des pièges pour en capturer et les identifier. Une fois l'inspection terminée, le diagnostiqueur établit un rapport qui décrit les résultats de l'inspection et les mesures à prendre pour traiter les termites. Il peut également recommander des mesures de prévention pour éviter les futurs problèmes d'infestation.</p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('33')">
                                    <span>{{ isOpen['33'] ? '-' : '+' }}</span>
                                                                                                                                                                                                                                                                                                                     
                                     Comment se déroule la réalisation d'un diagnostic électricité ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['33']">
                                   
                                    <p>La réalisation d'un diagnostic électricité consiste à vérifier l'état des installations électriques d'un bien immobilier, en se basant sur les normes en vigueur. Le diagnostiqueur électricité établit un rapport détaillé décrivant l'état des installations, les éventuelles anomalies constatées et les éventuels risques pour les occupants du bien. Il peut également formuler des recommandations pour remédier aux éventuels défauts constatés.</p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('34')">
                                    <span>{{ isOpen['34'] ? '-' : '+' }}</span>
                                                                                                                                                                                                                                                                                                                     
                                    Comment se déroule la réalisation d'un diagnostic gaz ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['34']">
                                   
                                    <p>La réalisation d'un diagnostic de gaz consiste à vérifier l'installation de gaz dans un bien immobilier pour s'assurer qu'elle est sécurisée et conforme aux normes en vigueur. Cela inclut l'inspection visuelle des équipements, la vérification de l'étanchéité des canalisations, la mesure des débits de gaz, et l'émission d'un rapport de conformité ou de non-conformité. Le diagnostic doit être réalisé par un professionnel certifié.</p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('35')">
                                    <span>{{ isOpen['35'] ? '-' : '+' }}</span>
                                                                                                                                                                                                                                                                                                                                                         
                                    Comment se déroule la réalisation d'un diagnostic amiante ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['35']">
                                   
                                    <p>La réalisation d'un diagnostic amiante consiste à rechercher la présence d'amiante dans les matériaux de construction d'un bâtiment. Cela peut être fait par un professionnel certifié qui utilise des équipements de détection spécifiques et effectue des prélèvements pour analyse en laboratoire. Si de l'amiante est détecté, un rapport est établi pour indiquer l'emplacement et l'état de ces matériaux ainsi que les éventuelles mesures à prendre.</p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('36')">
                                    <span>{{ isOpen['36'] ? '-' : '+' }}</span>
                                                                                                                                                                                                                                                                                                                                                                                         
                                     Comment se déroule la réalisation d'un diagnostic surface loi Carrez ?
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['36']">
                                   
                                    <p>La réalisation d'un diagnostic de superficie loi Carrez consiste en une mesure de la superficie privative des pièces principales du logement, telles que les chambres, le salon, la cuisine, etc. Le diagnostiqueur mesure ces pièces et les reporte sur un plan du logement. Il vérifie également que toutes les pièces sont déclarées dans le diagnostic, et que toutes les surfaces privatives sont correctement calculées. Il remet ensuite un rapport au propriétaire qui le transmet à l'acquéreur.</p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('37')">
                                    <span>{{ isOpen['37'] ? '-' : '+' }}</span>
                                                                                                                                                                                                                                                                                                                                                                                         
                                    Comment se déroule la réalisation d'un diagnostic surface loi Boutin ?

                                </div>
                                <div class="faq-answer" *ngIf="isOpen['37']">
                                   
                                    <p>Le diagnostic surface dans le cadre d'une location se réalise généralement lors de la mise en location d'un logement. Il a pour objectif de mesurer la superficie habitable du logement. Le diagnostiqueur immobilier se déplace sur les lieux pour effectuer les mesures nécessaires, puis établit un rapport qui sera remis au propriétaire et au locataire. Le propriétaire doit joindre ce diagnostic à la promesse de location ou au bail de location. Si les mesures indiquent une superficie inférieure à la surface annoncée, cela peut entraîner des conséquences sur le montant du loyer. </p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('38')">
                                    <span>{{ isOpen['38'] ? '-' : '+' }}</span>
                                                                                                                                                                                                                                                                                                                                                                                         
                                    Comment se déroule la réalisation d'un diagnostic assainissement ?

                                </div>
                                <div class="faq-answer" *ngIf="isOpen['38']">
                                   
                                    <p>Le diagnostic d'assainissement est un contrôle technique qui est effectué lors de la vente d'un bien immobilier. Il a pour but de vérifier que les systèmes de traitement des eaux usées fonctionnent correctement et sont bien entretenus. Pour réaliser ce diagnostic, il est nécessaire de contacter le service public d'assainissement de votre commune, qui pourra envoyer un technicien habilité et agréé pour effectuer cette tâche. Le technicien vérifiera notamment le type d'installation, son accessibilité, son respect des normes techniques et sanitaires en vigueur, détectera les éventuels défauts d'entretien et d'usure, relèvera les éventuels risques sanitaires et vérifiera que l'installation répond bien aux besoins de l'habitation et aux caractéristiques du terrain. Il délivrera un rapport valable 3 ans qui s'ajoutera aux sept autres diagnostics (amiante, plomb, gaz, termites, risques naturels et technologiques, installations électriques, performances énergétiques) insérés dans le dossier de diagnostic technique (DDT) qui doit être annexé à la promesse ou l'acte de vente. Si le diagnostic est non conforme, le vendeur peut être obligé de réaliser des travaux, notamment s'il présente un danger sanitaire ou un risque environnemental. </p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('39')">
                                    <span>{{ isOpen['39'] ? '-' : '+' }}</span>
                                                                                                                                                                                                                                                                                                                                                                                         
                                    Comment se déroule la réalisation d'un audit énergétique ?

                                </div>
                                <div class="faq-answer" *ngIf="isOpen['39']">
                                    <p>L'audit énergétique est un diagnostic qui permet d'évaluer les performances énergétiques d'un bâtiment. Il est réalisé par un professionnel certifié et comprend plusieurs étapes :</p>
                                    <p>Une analyse de la situation initiale : le diagnostiqueur effectue une visite du bâtiment pour recueillir des informations sur sa construction, son utilisation, ses équipements, etc.</p>
                                    <p>Une simulation thermique : le diagnostiqueur utilise un logiciel pour simuler les besoins énergétiques du bâtiment et identifier les points de consommation les plus importants.</p>
                                    <p>Une analyse des émissions de gaz à effet de serre : le diagnostiqueur calcule les émissions de gaz à effet de serre liées à l'utilisation du bâtiment.</p>
                                    <p>Une évaluation des améliorations possibles : le diagnostiqueur propose des solutions pour améliorer les performances énergétiques du bâtiment (isolation, équipements, etc.).</p>
                                    <p>Un rapport de synthèse : le diagnostiqueur rédige un rapport qui présente les résultats de l'audit et les améliorations possibles.</p>
                                   
                                </div>
                            </div>




                     

                           





                            <!-- Add more FAQ items as needed -->
                        </div>
                       
                       
                       

                     
                    </div>



                    <div class="services-details-desc items">
                        <h3 id="partJur">Partie juridique</h3>



                        <div class="faq-container">
                           

               
                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('40')">
                                    <span>{{ isOpen['40'] ? '-' : '+' }}</span>
                                                                                                                                                                                                                                                                                                                                                                                         
                                    Comment se déroule la réalisation d'un audit énergétique ?

                                </div>
                                <div class="faq-answer" *ngIf="isOpen['40']">
                                    <p><strong>Pour l'audit énergétique :</strong><br>L'audit énergétique ne concerne que la vente des maisons individuelles et les immeubles en monopropriétés.<br>
                                        Depuis le 1er Avril 2023, audit énergétique obligatoire pour les logements dont le DPE est noté F et G<br>
                                        Au 1er janvier 2025, audit énergétique obligatoire pour les logements dont le DPE est noté E<br>
                                        Au 1er janvier 2034, audit énergétique obligatoire pour les logements dont le DPE est noté D</p>
                                    <p><strong>Pour le DPE :</strong><br>Au 31 décembre 2024, l'ancienne version du DPE ne sera plus acceptée</p>
                                    <p><strong>Pour la location :</strong><br>Depuis le 25 Août 2022, gel des loyers pour les logements dont le DPE est noté F et G (plus de possibilités d'augmenter les loyers)<br>Au 1er janvier 2023, location interdite pour toutes habitations si la consommation annuelle est supérieure à 450 kwh/m² en énergie primaire (gaz ou fioul)</p>
                                   
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('41')">
                                    <span>{{ isOpen['41'] ? '-' : '+' }}</span>
                                                                                                                                                                                                                                                                                                                                                                                         
                                   
                                         Est-il possible de contester les résultats des diagnostics immobiliers ?

                                </div>
                                <div class="faq-answer" *ngIf="isOpen['41']">
                                    <p>Il est possible de contester les résultats des diagnostics immobiliers si vous estimez qu'ils ne sont pas justes ou qu'ils ne reflètent pas correctement l'état de l'immeuble. Il est important de noter cependant que pour contester les résultats, il est nécessaire de disposer de preuves solides pour étayer vos dires. Il est donc recommandé de faire appel à un expert ou un professionnel qualifié pour réaliser un contre-diagnostic.</p>
                                    <p>Il existe différentes manières de contester les résultats d'un diagnostic immobilier :</p>
                                    <p>En faisant appel à un expert ou un professionnel qualifié pour réaliser un contre-diagnostic, qui permettra de vérifier si les résultats sont justes et de les comparer avec ceux obtenus par le diagnostiqueur immobilier initialement.</p>
                                    <p>En s'adressant au tribunal judiciaire compétent pour demander une annulation de la vente si les résultats ont entraîné une erreur dans la décision d'achat.</p>
                                    <p>En saisissant les autorités compétentes (par exemple, la Direction générale de la concurrence, de la consommation et de la répression des fraudes (DGCCRF) si vous pensez qu'il y a eu une fraude ou une mauvaise utilisation des diagnostics.</p>
                                    <p>Il est donc important de bien vérifier les résultats des diagnostics immobiliers avant de signer un compromis ou un acte de vente, et de ne pas hésiter à faire appel à un expert ou un professionnel qualifié pour vous aider dans cette démarche.</p>
                                   
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('42')">
                                    <span>{{ isOpen['42'] ? '-' : '+' }}</span>
                                                                                                                                                                                                                                                                                                                                                                                         
                                   
                                    Quelles sont les sanctions en cas de non-respect des obligations de diagnostics immobiliers ?

                                </div>
                                <div class="faq-answer" *ngIf="isOpen['42']">
                                    <p>Les sanctions en cas de non-respect des obligations de diagnostics immobiliers peuvent être civiles ou pénales. Les sanctions civiles peuvent inclure des dommages et intérêts pour les acheteurs, des pénalités de retard pour les vendeurs et des annulations de ventes. Les sanctions pénales peuvent inclure des amendes et des peines d'emprisonnement pour les vendeurs et les notaires qui ne respectent pas les obligations de diagnostics. En outre, il est important de noter que les vendeurs et les notaires peuvent également être poursuivis pour tromperie ou escroquerie en cas de déclarations mensongères ou de dissimulation volontaire de certaines informations relatives aux diagnostics immobiliers.</p>
                                   
                                </div>
                            </div>




                     

                           





                            <!-- Add more FAQ items as needed -->
                        </div>
                       
                       
                       

                     
                    </div>


                    <div class="services-details-desc items">
                        <h3 id="Divers">Divers</h3>



                        <div class="faq-container">
                           

               
                       

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('43')">
                                    <span>{{ isOpen['43'] ? '-' : '+' }}</span>
                                                                                                                                                                                                                                                                                                                                                                                         
                                   
                                    Comment se passe la transmission des diagnostics immobiliers à l'acquéreur ?

                                </div>
                                <div class="faq-answer" *ngIf="isOpen['43']">
                                    <p>La transmission des diagnostics immobiliers à l'acquéreur se fait généralement par le vendeur, qui doit fournir les documents requis avant la signature de l'acte de vente. Les diagnostics immobiliers doivent être réalisés par des professionnels certifiés et doivent être valides. Les diagnostics doivent être remis à l'acquéreur avant la signature de l'acte de vente, et ces derniers doivent être inclus dans le compromis de vente ou l'acte authentique. Si les diagnostics ne sont pas remis à l'acquéreur avant la signature de l'acte de vente, celui-ci peut annuler la vente ou demander des dommages et intérêts. Si les diagnostics sont inexacts ou périmés, l'acquéreur peut également demander des dommages et intérêts.</p>
                                   
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('44')">
                                    <span>{{ isOpen['44'] ? '-' : '+' }}</span>
                                                                                                                                                                                                                                                                                                                                                                                         
                                   
                                   
                                     Peut-on vendre un bien immobilier sans avoir réalisé les diagnostics obligatoires ?

                                </div>
                                <div class="faq-answer" *ngIf="isOpen['44']">
                                    <p>Il est illégal de vendre un bien immobilier sans avoir réalisé les diagnostics immobiliers obligatoires. Ces diagnostics sont exigés par la loi pour protéger les acheteurs contre les risques liés à l'acquisition d'un bien immobilier. Si un vendeur vend un bien immobilier sans avoir réalisé les diagnostics obligatoires, il peut être poursuivi en justice et être tenu responsable des dommages causés à l'acquéreur. Il risque également des sanctions administratives, comme des amendes et des peines d'emprisonnement.</p>
                                   
                                </div>
                            </div>


                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('45')">
                                    <span>{{ isOpen['45'] ? '-' : '+' }}</span>
                                                                                                                                                                                                                                                                                                                                                                                         
                                                                                                                                         
                                        Quel est le rôle de l'agence immobilière dans les diagnostics immobiliers ?

                                </div>
                                <div class="faq-answer" *ngIf="isOpen['45']">
                                   <p>L'agence immobilière a un rôle de conseil et d'assistance pour les diagnostics immobiliers. Elle peut aider les propriétaires vendeurs à identifier les diagnostics obligatoires nécessaires pour la vente de leur bien et les orienter vers des professionnels qualifiés pour les réaliser. Elle peut également assister les propriétaires vendeurs dans la mise en place des diagnostics et dans la transmission des résultats à l'acquéreur. L'agence immobilière peut également informer les acheteurs sur les diagnostics obligatoires et les inciter à les demander avant l'achat d'un bien immobilier. Enfin, elle peut également jouer un rôle de médiation en cas de litige lié aux diagnostics immobiliers entre le propriétaire vendeur et l'acquéreur.</p>                                    
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('46')">
                                    <span>{{ isOpen['46'] ? '-' : '+' }}</span>
                                                                                                                                                                                                                                                                                                                                                                                         
                                                                                                                                         
                                    Peut-on réaliser les diagnostics immobiliers soi-même ?

                                </div>
                                <div class="faq-answer" *ngIf="isOpen['46']">
                                    <p>Il est généralement recommandé de faire appel à un professionnel pour réaliser les diagnostics immobiliers. Bien que techniquement il soit possible de les réaliser soi-même, les diagnostics doivent être réalisés par des personnes compétentes et certifiées pour être valides. De plus, en cas de problème ou de litige lié aux diagnostics, il est plus facile de se retourner contre un professionnel que contre un particulier. Il est donc préférable de faire appel à un diagnostiqueur immobilier certifié pour s'assurer de la qualité et de la fiabilité des diagnostics.</p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('47')">
                                    <span>{{ isOpen['47'] ? '-' : '+' }}</span>
                                                                                                                                                                                                                                                                                                                                                                                         
                                                                                                                                                                             
                                    Est-il possible de réaliser des diagnostics immobiliers pour des locaux professionnels ?

                                </div>
                                <div class="faq-answer" *ngIf="isOpen['47']">
                                    <p>Oui, il est possible de réaliser des diagnostics immobiliers pour des locaux professionnels. Les diagnostics obligatoires pour la vente ou la location d'un bien professionnel sont les mêmes que pour un bien résidentiel, c'est-à-dire : diagnostic de performance énergétique (DPE), état des risques et pollutions (ERP), diagnostic électrique, diagnostic gaz, et diagnostic plomb (si le bâtiment a été construit avant le 1er janvier 1949). Il est important de noter que pour les bâtiments professionnels, il est également obligatoire de réaliser des diagnostics spécifiques tels que l'état de l'installation électrique et de l'éclairage public, l'état des matériels de levage, l'état des installations de sécurité incendie, etc.</p>
                                </div>
                            </div>

                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('48')">
                                    <span>{{ isOpen['48'] ? '-' : '+' }}</span>
                                                                                                                                                                                                                                                                                                                                                                                         
                                                                                                                                                                                                               

                                    Quelles sont les différences entre les diagnostics immobiliers pour un bien neuf et un bien ancien ?

                                </div>
                                <div class="faq-answer" *ngIf="isOpen['48']">
                                    <p>Il existe des différences entre les diagnostics immobiliers pour un bien neuf et un bien ancien. Pour un bien neuf, il n'est pas nécessaire de réaliser de diagnostics de performance énergétique (DPE) ou d'état des risques et pollutions (ERP) car ils ont déjà été réalisés lors de la construction du bâtiment. Cependant, il est obligatoire de réaliser un diagnostic électricité et gaz pour un bien neuf.

                                        Pour un bien ancien, il est obligatoire de réaliser un DPE, un ERP, un diagnostic électricité et gaz, ainsi qu'un diagnostic de termites si le bien est situé dans une zone à risque. Si l'immeuble a été construit avant le 1er janvier 1949, il est également nécessaire de réaliser un diagnostic plomb.</p>
                                </div>
                            </div>


                           
                            <div class="faq-item">
                                <div class="faq-question" (click)="toggleAnswer('49')">
                                    <span>{{ isOpen['49'] ? '-' : '+' }}</span>
                                   
                                   Quelles sont les obligations légales du propriétaire d'un bien immobilier en cas de résultats dangereux ou non conformes aux diagnostics immobiliers réalisés ?

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
                                </div>
                                <div class="faq-answer" *ngIf="isOpen['49']">
                                    <p>Les obligations du propriétaire du bien à la suite des résultats des diagnostics immobiliers dépendent de la nature des problèmes révélés. Si les diagnostics révèlent des risques pour la santé ou la sécurité des occupants, le propriétaire est tenu de les remédier dans les plus brefs délais. Si les diagnostics révèlent des défauts ou des anomalies qui ont un impact sur la valeur du bien, le propriétaire doit en informer l'acquéreur avant la vente. Si les diagnostics révèlent des problèmes qui n'ont pas d'impact sur la santé ou la sécurité des occupants ni sur la valeur du bien, le propriétaire n'a pas d'obligation légale de les remédier. Toutefois, il est recommandé de les corriger pour éviter des problèmes à l'avenir.</p>
                                </div>
                            </div>




                     

                           





                            <!-- Add more FAQ items as needed -->
                        </div>
                       
                       
                       

                     
                    </div>



                </div>


               
                <div class="uk-sidebar uk-width-1-5 uk-flex-first@l uk-first-column">
                    <!--
                    <div class="widget widget_search">
                        <form>
                            <input type="text" class="uk-input" placeholder="Search here...">
                            <button type="submit"><i class="flaticon-search"></i></button>
                        </form>
                    </div>
-->
<div class="widget service_list liste">
    <h4 style="text-align: center;">Questions Fréquentes</h4>
    <ul>
        <li><a (click)="scrollToSection('diagOb')">Diagnostics obligatoires <i class="flaticon-right"></i></a></li>
        <li><a (click)="scrollToSection('audit')">Audit énergétique <i class="flaticon-right"></i></a></li>
        <li><a  (click)="scrollToSection('parCom')">Parties communes <i class="flaticon-right"></i></a></li>
        <li><a  (click)="scrollToSection('ValDiagImo')">Validité des diagnostics immobilier <i class="flaticon-right"></i></a></li>
        <li><a (click)="scrollToSection('diagProcess')">Déroulement d’un diagnostic immobilier <i class="flaticon-right"></i></a></li>
        <li><a  (click)="scrollToSection('partJur')">Partie juridique <i class="flaticon-right"></i></a></li>
        <li><a  (click)="scrollToSection('Divers')">Divers <i class="flaticon-right"></i></a></li>
    </ul>
</div>



   
                </div>


            </div>
        </article>
    </div>
</section>
<!-- End Services Details Area -->
