<!-- Start Page Title Area -->

<section class="funfacts-area uk-section uk-funfacts sec11">
    <div class="overlay"></div>

    <div class="uk-container">
        <div class="text-content">
            <h1 class="h1-sec11">S.A Diag</h1>
        <h5><p class="p-sec11">DIAGNOSTICS IMMOBILIERS À PARIS ET DANS TOUTE LA FRANCE</p></h5>
            <div class="button-container">
            </div>
        </div>
    </div>
</section>

<!-- End Page Title Area -->

<!-- Start Project Details Area -->
<section class="project-details-area uk-project-details uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-flex project-details">
            <div class="item uk-width-1-5" >
                <div class="project-details-info box-devis">
                    <h3>Un devis en quelques clics</h3>
                    <ul>
                     
                        <li> <span>✔️</span> Devis gratuit en ligne en 2 minutes</li>
                        <li><span>✔️</span> Prise de rendez-vous simple</li>
                        <li><span>✔️</span> Des prix en toute transparence</li>
                        
                        <a (click)="routertodevis()" class="uk-button uk-button-default btn-dev">Devis en Ligne Gratuit !</a>

                    </ul>

                </div>

            </div>
            <div class="project-details-img uk-width-expand">
                <img src="assets/image/diag_plomb.webp" alt="image">
                <div class="project-details-desc">
                    <h3>Plomb (Crep)​​​</h3>
                    <span class="spn1">+  <span class="spn2">Vente : obligatoire pour les constructions avant 1949 </span></span><br>
                    <span class="spn1">+  <span class="spn2">Location : obligatoire pour les constructions avant 1949</span></span><br>
                    <span class="spn1">+  <span class="spn2">Durée de validité : Illimité si abscence de plomb. En cas de présence, il est valable 1 an pour la vente et 6 ans pour la location.</span></span>
        
        
        
        
                    <blockquote class="blockquote">
                        <p class="p-desc">Le constat de risque d'exposition au plomb (Crep), aussi appelé diagnostic plomb, est un document qui donne des informations sur la présence de plomb dans les logements. Votre logement est concerné par ce diagnostic s'il a été construit avant 1949. Le Crep doit être intégré au dossier de diagnostic technique (DDT). Le DDT doit être remis à l'acquéreur ou locataire en cas de vente ou location d'un logement.​
                        </p>
                    </blockquote>
        
                </div>
            </div>

          
        </div>

       

     
    </div>
</section>
<!-- End Project Details Area -->
