<!-- Start Page Title Area -->

<section class="funfacts-area uk-section uk-funfacts sec11">
    <div class="overlay"></div>

    <div class="uk-container">
        <div class="text-content">
            <h1 class="h1-sec11">S.A Diag</h1>
        <h5><p class="p-sec11">DIAGNOSTICS IMMOBILIERS À PARIS ET DANS TOUTE LA FRANCE</p></h5>
            <div class="button-container">
            </div>
        </div>
    </div>
</section>

<!-- End Page Title Area -->

<div class="uk-container" style="margin-top: 60px; text-align: center;">
    <h2>Diagnostic amiante pour les parties communes</h2>
   <p>Notre produit "Diagnostics parties communes" est un service de diagnostic immobilier complet visant à identifier les risques liés à la présence d'amiante, de plomb et de termites dans les parties communes d'un immeuble. À la demande du syndic ou de la copropriété, nous réalisons des analyses approfondies pour vous permettre de garantir la sécurité et la santé de tous les occupants de l'immeuble.</p> 

    <div class="bar"></div>

    
</div>


<!-- Start Project Details Area -->
<section class="project-details-area uk-project-details uk-section">
     
    
       
<div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s div-sec9">

    <div class="uk-container" style="margin: auto;">

           
    <div  style="margin-left: 40px;" >
        <div class="faq-question" (click)="toggleAnswer('1')" >
            <span>{{ isOpen['1'] ? '-' : '+' }}</span>
                                                                                                                                                                                                                                                                                                                                                                 
            
            Diagnostic amiante pour les parties communes

        </div>
        <div class="faq-answer" *ngIf="isOpen['1']">
            <p>Le diagnostic amiante pour les parties communes, dit « Dossier Technique Amiante » (DTA), est une étape cruciale pour garantir la sécurité des occupants d’un immeuble. Nous réalisons une analyse minutieuse de toutes les parties communes pour détecter la présence d’amiante. Le dossier fourni au syndic ou à la copropriété est nécessaire pour la vente de tout bien en copropriété. Il devra être envoyé au notaire.</p>
            
        </div>

<div style="margin-top: 60px;">

        <div class="faq-question" (click)="toggleAnswer('2')">
            <span>{{ isOpen['2'] ? '-' : '+' }}</span>
                                                                                                                                                                                                                                                                                                                                                                 
            
            Diagnostic plomb pour les parties communes

        </div>
        <div class="faq-answer" *ngIf="isOpen['2']">
            <p>Le diagnostic plomb pour les parties communes permet d’identifier la présence de plomb dans les parties communes et de proposer des mesures pour protéger la santé des occupants de l’immeuble. Nous réalisons des analyses rigoureuses pour garantir la sécurité de tous les habitants.</p>
            
        </div>
        </div>


        <div style="margin-top: 60px; margin-bottom: 20px;">

            <div class="faq-question" (click)="toggleAnswer('3')">
                <span>{{ isOpen['3'] ? '-' : '+' }}</span>
                                                                                                                                                                                                                                                                                                                                                                     
                
                Diagnostic termites pour les parties communes
    
            </div>
            <div class="faq-answer" *ngIf="isOpen['3']">
                <p>Le diagnostic termites pour les parties communes permet de repérer la présence de termites dans les parties communes et de proposer des solutions pour éliminer ces insectes nuisibles. Nous sommes à votre disposition pour réaliser un diagnostic complet et vous aider à garantir la sécurité de votre immeuble.</p>
                
            </div>
            </div>


    </div>

   


</div>    
    <img    src="/assets/image/partie_communes.webp" alt="image" class="toggle-image" style="min-height: 300px;">

</div>

     
</section>
<!-- End Project Details Area -->

