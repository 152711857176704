<!-- Start Footer Area -->
<footer id="footer" class="footer-area {{location == '/' ? '' : 'uk-dark'}} uk-footer">

    <div class="footer-container">
      <div class="footer-column">
        <img src="/assets/image/logo.png" alt="Logo" class="footer-logo">
        <div class="barr"></div>
        <p>S.A Diag, un réseau de professionnels du diagnostic immobilier à votre disposition, à Paris et dans toute la France !</p>
        <p>Nos services: Diagnostic immobilier pour location, Diagnostic immobilier pour vente, Audit énergétique réglementaire, Audit énergétique incitatif, Diagnostic Amiante avant travaux</p>
        <a routerLink="/devis-gratuit" class="uk-button uk-button-default" style="color: white;">Devis gratuit en ligne !</a>      </div>
  
      <div class="footer-column">
        <h3>S.A Diag</h3>
        <div class="bar"></div>
        <a routerLink="/diagnostics-immobiliers-obligatoires">Diagnostics</a>
        <a routerLink="/contact">Contactez-nous</a>
        <a routerLink="/devis-gratuit">Devis gratuit en ligne !</a>
      </div>
  
      <div class="footer-column">
        <h3>Nos diagnostics</h3>
        <div class="bar"></div>
        <a routerLink="/dpe">Diagnostic immobilier DPE</a>
        <a routerLink="/amiante">Diagnostic amiante</a>
        <a routerLink="/carrez">Métrage (Loi Carrez)</a>
        <a routerLink="/termites">Diagnostic termites</a>
        <a routerLink="/électricité">Diagnostic électricité</a>
        <a routerLink="/gaz">Diagnostic gaz</a>
        <a routerLink="/plomb">Diagnostic plomb</a>
        <a routerLink="metrage">Métrage (Loi Boutin)</a>
        <a routerLink="/erp">Risques et pollutions (ERP)</a>
        <a routerLink="/Audit-énergétique">Audit énergétique incitatif</a>
        <a routerLink="/Audit-énergétique">Audit énergétique rénovation</a>
      </div>
  
      <div class="footer-column">
        <h3>Nos diagnostics Pros</h3>
        <div class="bar"></div>
        <a routerLink="/vente-et-location">Diagnostics local professionnel</a>
        <a routerLink="/amiante-travaux-algorithme">Diagnostic amiante travaux</a>
        <a routerLink="/amiante-travaux-algorithme">Diagnostic amiante démolition</a>
        <a routerLink="/Copropriete">Diagnostic parties communes</a>
        <a routerLink="/miseenCopropriete">Mise en copropriété</a>
        <a routerLink="/techniqueGlobal">Diagnostic technique global (DTG)</a>
      </div>
  
      <div class="footer-column">
        <h3>Nos atouts</h3>
        <div class="bar"></div>
        <a routerLink="/devis-gratuit">Diagnostic immobilier</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier prix</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier tarif</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier location</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier location obligatoire</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier obligatoire</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier obligatoire vente</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier vente</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier validité</a>
      </div>
    </div>
    <div class="bare"></div>
    <h3 id="hh3">Notre réseau de diagnostiqueurs immobiliers certifiés en France</h3>
    <div class="footer-container">
      <div class="footer-column">
       
        <a routerLink="/devis-gratuit">Diagnostic immobilier Paris</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier 77</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier 78</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier 91</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier 92</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier 93</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier 94</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier 95</a>
        </div>
        <div class="footer-column">
          
        <a routerLink="/devis-gratuit">Diagnostic immobilier Marseille</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Lyon</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Toulouse</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Nice</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Nantes</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Montpellier</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Strasbourg</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Bordeaux</a>
        </div>
        <div class="footer-column">
        
        <a routerLink="/devis-gratuit">Diagnostic immobilier Lille</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Rennes</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Reims</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Toulon</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Saint-Étienne</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Le Havre</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Grenoble</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Dijon</a>
        </div>
        <div class="footer-column">
       
        <a routerLink="/devis-gratuit">Diagnostic immobilier Angers</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Saint-Denis</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Villeurbanne</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Nîmes</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Clermont-Ferrand</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Aix-en-Provence</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Le Mans</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Brest</a>
      </div>
      <div class="footer-column">
        
        <a routerLink="/devis-gratuit">Diagnostic immobilier Tours</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Amiens</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Limoges</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Annecy</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Orléans</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Perpignan</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Metz</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Besançon</a>
    </div>
       
      </div>
      <div class="bare"></div>
      <h3 id="hh3">Notre réseau de diagnostiqueurs immobiliers certifiés en région parisienne</h3>
      <div class="footer-container">
        
        <div class="footer-column">
          <a routerLink="/devis-gratuit">Diagnostic immobilier Alfortville</a>
          <a routerLink="/devis-gratuit">Diagnostic immobilier Antony</a>
          <a routerLink="/devis-gratuit">Diagnostic immobilier Asnieres sur seine</a>
          <a routerLink="/devis-gratuit">Diagnostic immobilier Bagnolet</a>
          <a routerLink="/devis-gratuit">Diagnostic immobilier Boulogne billancourt</a>
          <a routerLink="/devis-gratuit">Diagnostic immobilier Bry sur marne</a>
          <a routerLink="/devis-gratuit">Diagnostic immobilier Cachan</a>
          <a routerLink="/devis-gratuit">Diagnostic immobilier Champigny sur marne</a>
          <a routerLink="/devis-gratuit">Diagnostic immobilier Vitry sur seine</a>
          </div>
        <div class="footer-column">
          <a routerLink="/devis-gratuit">Diagnostic immobilier Charenton le pont</a>
          <a routerLink="/devis-gratuit">Diagnostic immobilier Chatillon</a>
          <a routerLink="/devis-gratuit">Diagnostic immobilier Clamart</a>
          <a routerLink="/devis-gratuit">Diagnostic immobilier Clichy</a>
          <a routerLink="/devis-gratuit">Diagnostic immobilier Colombes</a>
          <a routerLink="/devis-gratuit">Diagnostic immobilier Courbevoie</a>
          <a routerLink="/devis-gratuit">Diagnostic immobilier Creteil</a>
          <a routerLink="/devis-gratuit">Diagnostic immobilier Gentilly</a>
          <a routerLink="/devis-gratuit">Diagnostic immobilier St maurice</a>
    
      <!-- Column 3 -->
  
          <a routerLink="/devis-gratuit">Diagnostic immobilier Sucy en brie</a>
          <a routerLink="/devis-gratuit">Diagnostic immobilier Thiais</a>
        </div>
    
        <div class="footer-column">
          <a routerLink="/devis-gratuit">Diagnostic immobilier Issy les moulineaux</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Ivry sur seine</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Joinville le pont</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier La garenne colombes</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Le kremlin bicetre</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Le perreux sur marne</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Le plessis robinson</a>

        <a routerLink="/devis-gratuit">Diagnostic immobilier Les lilas</a>
        </div>
    
        <div class="footer-column">
          <a routerLink="/devis-gratuit">Diagnostic immobilier Levallois perret</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Maisons alfort</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Malakoff</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Meudon</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Montreuil</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Montrouge</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Nanterre</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Neuilly sur seine</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Villejuif</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Villiers sur marne</a>
        <a routerLink="/devis-gratuit">Diagnostic immobilier Vincennes</a>
        </div>
    
        <div class="footer-column">

              <a routerLink="/devis-gratuit">Diagnostic immobilier Nogent sur marne</a>
              <a routerLink="/devis-gratuit">Diagnostic immobilier Noisy le grand</a>
              <a routerLink="/devis-gratuit">Diagnostic immobilier Orly</a>
              <a routerLink="/devis-gratuit">Diagnostic immobilier Puteaux</a>
              <a routerLink="/devis-gratuit">Diagnostic immobilier Rueil malmaison</a>
              <a routerLink="/devis-gratuit">Diagnostic immobilier Sceaux</a>
              <a routerLink="/devis-gratuit">Diagnostic immobilier Sevres</a>
              <a routerLink="/devis-gratuit">Diagnostic immobilier St cloud</a>
              <a routerLink="/devis-gratuit">Diagnostic immobilier St mande</a>
              <a routerLink="/devis-gratuit">Diagnostic immobilier St maur des fosses</a>
      </div>
      </div>
<!-- End Footer Area -->


        <div class="copyright-area">
            <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-2@s">
                <div class="item">
                    <p>
                        Copyright 2024 WasRam IT Tous droits réservés</p>
                </div>

                <div class="item">
                    <ul>
                        <li><a (click)="navigateToExternalSite()" target="_blank">Développé par WasRam IT
                        </a></li>
                  
                    </ul>
                </div>
            </div>
            <div class="go-top"><i class="flaticon-chevron"></i></div>
        </div>


    <div class="br-line"></div>
    <div class="footer-shape1"><img src="assets/img/footer-shape1.png" alt="shape"></div>
    <div class="footer-shape2"><img src="assets/img/footer-shape2.png" alt="shape"></div>
</footer>
<!-- End Footer Area -->
