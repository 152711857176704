<!-- Start section 1 -->
<div id="home" class="uk-banner main-banner item-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="uk-container">
                <div class="main-banner-content">
                    <h1 class="h1-sec1">S.A <br>Diag</h1>
                    <p class="p-sec1">le site consacré aux diagnostics immobiliers, destiné aux <span class="sec1-span">particuliers et aux professionnels !</span></p>

                    <a (click)="sendemail()" class="uk-button uk-button-default">contact@sadiag.com</a>

                    <a (click)="sendphone()" class="video-btn tel"><img src="/assets/image/call.png">01 59 38 01 80</a>
                </div>
            </div>
        </div>
    </div>
</div>
 <div class="uk-container" style="text-align: center; margin-top: 40px;">
<h3 class="h3-sec2-cont">Découvrez nos 6 formulaires dédiés : obtenez votre devis en ligne en quelques clics !</h3>
</div>



<div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s sec2-cont">
    <div class="item">
        <div class="single-services">
            <div class="icon">
                <img src="/assets/image/location.webp" class="diag-img" >
            </div>
            <h3>Diagnostics Immobiliers</h3>
            (Vente & Location : DPE...)

            <i class="flaticon-right link-btn"></i>

            <a routerLink="/vente-et-location" class="link uk-position-cover"></a>
        </div>
    </div>

    <div class="item">
        <div class="single-services">
            <div class="icon">
                <img src="/assets/image/audit.webp" class="diag-img" >
            </div>
            <h3>Audit énergétique</h3>
            (Rénovation - Incitatif...)

            <i class="flaticon-right link-btn"></i>

            <a routerLink="/Audit-énergétique" class="link uk-position-cover"></a>
        </div>
    </div>

    <div class="item">
        <div class="single-services">
            <div class="icon">
                <img src="/assets/image/Amiante.webp" class="diag-img" >
            </div>
            <h3>Amiante travaux</h3>
            (DTA, Amiante travaux, Plomb travaux)

            <i class="flaticon-right link-btn"></i>

            <a routerLink="/amiante-travaux-algorithme" class="link uk-position-cover"></a>
        </div>
    </div>

    <div class="item">
        <div class="single-services">
            <div class="icon">
                <img src="/assets/image/loco-pro.webp" class="diag-img" >
            </div>
            <h3>Locaux Professionnels</h3>
            (DPE, DTA, Plomb,Termites, Etat parasitaires, Assainissement, Audit énergétique)

            <i class="flaticon-right link-btn"></i>

            <a routerLink="/locaux-professionnel" class="link uk-position-cover"></a>
        </div>
    </div>

    <div class="item">
        <div class="single-services">
            <div class="icon">
                <img src="/assets/image/copr.webp" class="diag-img" >
            </div>
            <h3>Copropriété</h3>
            (DTG, DPE, DTA, Termites, Plomb)

            <i class="flaticon-right link-btn"></i>

            <a routerLink="/miseenCopropriete" class="link uk-position-cover"></a>
        </div>
    </div>

    <div class="item">
        <div class="single-services">
            <div class="icon">
                <img src="/assets/image/mise-en-copr.webp" class="diag-img" >
            </div>
            <h3>Mise en Copropriété</h3>
            (Géomètre - DTG)

            <i class="flaticon-right link-btn"></i>

            <a routerLink="/Demandeparticulière" class="link uk-position-cover"></a>
        </div>
    </div>

   
   

  
</div>
<!-- End section 2  -->

<!-- Start section 3  -->

<div class="uk-container sec3-cont">
    <h2 class="h2-sec3">Un large choix de <span class="span-sec3">diagnostics</span> adaptés selon votre projet</h2>
    <div class="div-sec3">
        <h3 class="h3-sec3">
            Diagnostics obligatoires dans le cadre d'une vente ou d'une location d'un bien
        </h3>
        
        <ul class="ul-sec3">
            <li class="li-sec3">
                <span>✔️</span> 
                Diagnostics immobiliers obligatoires appartement
                <a routerLink="/vente-et-location" class="hover-button" >Demande de devis gratuit</a>
            </li>
            <li class="li-sec3">
                <span>✔️</span> 
                Diagnostics immobiliers obligatoires maison
                <a routerLink="/vente-et-location" class="hover-button" >Demande de devis gratuit</a>
            </li>
            <li class="li-sec3">
                <span>✔️</span> 
                Diagnostics immobiliers obligatoires immeuble
                <a routerLink="/vente-et-location" class="hover-button" >Demande de devis gratuit</a>
            </li>
            <li class="li-sec3">
                <span>✔️</span> 
                Diagnostics immobiliers obligatoires cave
                <a  routerLink="/vente-et-location" class="hover-button" >Demande de devis gratuit</a>
            </li>
            <li class="li-sec3">
                <span>✔️</span> 
                Diagnostics immobiliers obligatoires parking
                <a routerLink="/vente-et-location" class="hover-button" >Demande de devis gratuit</a>
            </li>
            <li class="li-sec3">
                <span>✔️</span> 
                Diagnostics immobiliers obligatoires local professionnel : commerce - bureaux - hôtel - atelier - entrepôt - bâtiment industriel
                <a routerLink="/vente-et-location" class="hover-button" >Demande de devis gratuit</a>
            </li>
        </ul>
    </div>
    
    
    
    <div class="uk-container cont2">

        <div class="div-sec3-grid-v">
            <h4 class="h4-sec3">
                Audit énergétique    
            </h4>
            <ul class="ul-sec3">
                <li class="li-sec3">
                    <span>✔️</span> 
                    Audit énergétique dans le cadre d'une vente         
                       <a class="hover-button2" routerLink="/Audit-énergétique">Demande de devis gratuit</a>
                </li>
                <li class="li-sec3">
                    <span>✔️</span> 
                    Audit énergétique dans le cadre d'une rénovation      
                          <a class="hover-button2" routerLink="/Audit-énergétique">Demande de devis gratuit</a>
                </li>
                <li class="li-sec3">
                    <span>✔️</span> 
                    Audit énergétique incitatif         
                       <a class="hover-button2" routerLink="/Audit-énergétique">Demande de devis gratuit</a>
                </li>
            
              
              
            </ul>
        </div>
        
        <div class="div-sec3-grid-v">
            <h4 class="h4-sec3">
                Diagnostic amiante pour les professionnels
                </h4>
            <ul class="ul-sec3">
                <li class="li-sec3">
                    <span>✔️</span> 
                    Diagnostic amiante avant travaux de rénovation       
                       <a class="hover-button2" routerLink="/amiante-travaux-algorithme" >Demande de devis gratuit</a>
                </li>
                <li class="li-sec3">
                    <span>✔️</span> 
                    Diagnostic amiante avant démolition     
                          <a class="hover-button2"routerLink="/amiante-travaux-algorithme" >Demande de devis gratuit</a>
                </li>
                <li class="li-sec3">
                    <span>✔️</span> 
                    Plomb avant Travaux
                   <a class="hover-button2" routerLink="/amiante-travaux-algorithme">Demande de devis gratuit</a>
                </li>
            
                <li class="li-sec3">
                    <span>✔️</span> 
                      Contrôle Amiante après Travaux           
                      <a class="hover-button2" routerLink="/amiante-travaux-algorithme">Demande de devis gratuit</a>
                </li>
        
                <li class="li-sec3">
                    <span>✔️</span> 
                    Contrôle Plomb après Travaux         
                      <a class="hover-button2"routerLink="/amiante-travaux-algorithme" >Demande de devis gratuit</a>
                </li>
        
                <li class="li-sec3">
                    <span>✔️</span> 
                    Amiante voirie        
                      <a class="hover-button2" routerLink="/amiante-travaux-algorithme">Demande de devis gratuit</a>
                </li>
              
              
            </ul>
        </div>
        
        
        <div class="div-sec3-grid-v">
            <h4 class="h4-sec3">
                Copropriétés
                </h4>
            <ul class="ul-sec3">
                <li class="li-sec3">
                    <span>✔️</span> 
                    Diagnostics parties communes des immeubles en copropriété         
                       <a class="hover-button2" routerLink="/miseenCopropriete" >Demande de devis gratuit</a>
                </li>
                <li class="li-sec3">
                    <span>✔️</span> 
                    
                          DTG immeuble     
                          <a class="hover-button2" routerLink="/miseenCopropriete">Demande de devis gratuit</a>
                </li>
                <li class="li-sec3">
                    <span>✔️</span> 
                   
                      DPE Collectif immeuble        
                       <a class="hover-button2" routerLink="/miseenCopropriete">Demande de devis gratuit</a>
                </li>
        
                <li class="li-sec3">
                    <span>✔️</span> 
                   
                    DTA (Dossier Technique Amiante)       
                       <a class="hover-button2"routerLink="/miseenCopropriete" >Demande de devis gratuit</a>
                </li>
        
                <li class="li-sec3">
                    <span>✔️</span> 
                   
                    Termites Parties Communes      
                       <a class="hover-button2"routerLink="/miseenCopropriete" >Demande de devis gratuit</a>
                </li>
        
                <li class="li-sec3">
                    <span>✔️</span> 
                   
                    Plomb Parties Communes      
                       <a class="hover-button2" routerLink="/miseenCopropriete">Demande de devis gratuit</a>
                </li>
        
                <li class="li-sec3">
                    <span>✔️</span> 
                   
                    Assainissement     
                       <a class="hover-button2" routerLink="/miseenCopropriete">Demande de devis gratuit</a>
                </li>
            
                <li class="li-sec3">
                    <span>✔️</span> 
                   
                    Audit énergétique   
                       <a class="hover-button2"routerLink="/miseenCopropriete" >Demande de devis gratuit</a>
                </li>
        
                <li class="li-sec3">
                    <span>✔️</span> 
                   
                    
                      Mise en copropriété d'un immeuble  
                       <a class="hover-button2" routerLink="/Demandeparticulière" >Demande de devis gratuit</a>
                </li>
            
            
            
              
              
            </ul>
        </div>
        
        </div>
</div>

<!-- end section 3  -->


<!-- start section 4  -->

<section class="uk-features features-area uk-section uk-padding-remove-top sec4">
    
    <div class="uk-container">
        <h2 class="h2-sec2">Pour vos diagnostics immobiliers obligatoires, obtenez un devis et un prix en ligne pour la vente ou location de votre maison et appartement</h2>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m div-sec4">
            <div class="uk-item">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-globe"></i>
                    </div>
                    <h3>Un réseau de professionnels expérimentés</h3>
                    <div class="bar"></div>
                    <p>Profitez d’un réseau national de plus de 100 diagnostiqueurs immobiliers certifiés et assurés à Paris et sur toute la France.</p>

                  
                    <div class="animation-img">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-file"></i>
                    </div>
                    <h3>Un devis rapide et gratuit</h3>
                    <div class="bar"></div>
                    <p>Obtenez en moins de 2 minutes un devis incluant la liste de vos diagnostics immobiliers obligatoires</p>

                   

                    <div class="animation-img">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-calendar-plus"></i>
                    </div>
                    <h3>Une interaction fluide et transparente</h3>
                    <div class="bar"></div>
                    <p>Prise de rendez-vous et paiement en ligne. Nous mettons la technologie à votre service pour vous simplifier la vie !</p>


                    <div class="animation-img">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- end section 4  -->



<div class="separate">
    <div class="br-line"></div>
</div>

<!-- start section 5 -->
<section id="about" class="uk-about about-area uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <span>S.A DIAG</span>
                        <h2>Un devis en quelques clics, une prise de rendez-vous simple, des prix en toute transparence</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text">
                        <div class="icon">
                            <i class="flaticon-quality"></i>
                        </div>
                        <h3>Découvrez avec S.A Diag tous les diagnostics immobiliers obligatoires dans le cadre de la vente ou de la location d'une maison ou d'un appartement</h3>
                        <ul class="ul-sec3">
                            <li class="li-sec3">
                                <span>✔️</span> 
                                Diagnostics immobiliers obligatoires appartement
                            </li>
                            <li class="li-sec3">
                                <span>✔️</span> 
                                Diagnostics immobiliers obligatoires maison
                            </li>
                            <li class="li-sec3">
                                <span>✔️</span> 
                                Diagnostics immobiliers obligatoires immeuble
                            </li>
                          
                           
                          
                        </ul>

                        <div class="signature">
                            <img src="assets/image/signature.png" alt="signature">
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="about-img">
                    <img src="/assets/image/dev-grat1.webp" class="about-img1"  alt="about-img">
                    <img src="/assets/image/dev-grat2.jpg" class="about-img2" alt="about-img">

                </div>
            </div>
        </div>
    </div>
</section>
<!-- End section 5 -->

<div class="separate">
    <div class="br-line"></div>
</div>

 <!-- start section 6 -->
<section class="page-title-area uk-page-title">
        <div class="uk-container">
            <div class="sec6-div">
                <h1 style="color: #263548;">Nos Atouts</h1>
                <ul>
                <li class="li-sec3">
                    <span>✔️</span> 
                    Un réseau de diagnostiqueurs professionnels à Paris et dans toute la France           
                     </li>
                <li class="li-sec3">
                    <span>✔️</span> 
                    Un système informatique de pointe à votre service
                </li>
                <li class="li-sec3">
                    <span>✔️</span> 
                    Des prix en toute transparence
                </li>
                </ul>
                <a routerLink="/devis-gratuit" class="uk-button uk-button-default sec6-btn">Devis gratuit en ligne !</a>

            </div>
        </div>
</section>
<!-- end section 6 -->


<!-- start section 7 -->
<section id="testimonials" class="feedback-section uk-process uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-text-center">
            <h2>Les diagnostics immobiliers obligatoires</h2>
            Pour vos diagnostics immobiliers obligatoires, obtenez un devis et un prix en ligne pour la vente ou location de votre maison et appartement

            <div class="bar"></div>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-feedback-item">
                    <img class="img-sec7" src="/assets/image/DPE.webp">
                    <div class="div-h4-sec7">
                        <h4>Diagnostic Performance énergétique (DPE)</h4>


                        <span class="span-sec7"><b>Vente :</b> obligatoire</span><br>
                        <span class="span-sec7"><b>Location :</b> obligatoire</span><br>
                        <span class="span-sec7"><b>Durée de validité :</b> 10 ans</span>
                       
                    </div>
                    <p class="sec7-p">Le DPE sert principalement à évaluer la quantité d'énergie et de gaz à effet de serre de votre logement. Vous devez faire réaliser ce diagnostic par un professionnel certifié. Vous devez intégrer le DPE au dossier de diagnostic technique (DDT) et le remettre à l'acquéreur ou au locataire lorsque vous vendez ou louez votre logement.</p>
  
                    <a routerLink="/PerformanceDPE" class="a-sec7">En savoir plus &rarr;</a>
            </div>
            </div>

            <div class="item">
                <div class="single-feedback-item">
                    <img class="img-sec7" src="/assets/image/habita.webp" width="90">
                    <div class="div-h4-sec7">
                        <h4>Diagnostic Amiante</h4>
                        <span class="span-sec7"><b>Vente :</b> obligatoire pour les constructions avant 1997</span><br>
                        <span class="span-sec7"><b>Location :</b> obligatoire pour les constructions avant 1997</span><br>
                        <span class="span-sec7"><b>Durée de validité :</b> Illimité si abscence d'amiante. Il est conseillé de réaliser un diagnostic amiante avant chaque vente.</span>
                    </div>
                    <p class="sec7-p">
                        L'état d'amiante, aussi appelé diagnostic amiante, est un document qui mentionne la présence ou l'absence de matériaux ou produits contenant de l'amiante dans un
                    </p>
                    <a routerLink="/amiante" class="a-sec7">En savoir plus &rarr;</a>
                </div>
            </div>
            

            <div class="item">
                <div class="single-feedback-item">
                    <img class="img-sec7" src="/assets/image/habitablee.webp">
                    <div class="div-h4-sec7">
                        
                        <h4>Diagnostic Métrage (Loi Carrez)</h4>
                        <span class="span-sec7"><b>Vente :</b>  obligatoire pour les biens en copropriété</span><br>
                        <span class="span-sec7"><b>Location :</b> optionnel</span><br>
                        <span class="span-sec7"><b>Durée de validité :</b>  Illimité, sauf travaux de réagencement</span>
                       
                    </div>
                    <p class="sec7-p">Le calcul de la surface habitable d’une maison ou d’un appartement fait partie des diagnostics obligatoires avant la signature d’un contrat de vente. Il sera exigé à la signature du compromis de vente. Il doit être réalisé par un diagnostiqueur formé et...</p>
                    
                    <a routerLink="/carrez" class="a-sec7">En savoir plus &rarr;</a>
                </div>
            </div>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-feedback-item">
                    <img class="img-sec7" src="/assets/image/gg.webp">
                    <div class="div-h4-sec7">
                        <h4>Diagnostic Termites</h4>


                        <span class="span-sec7"><b>Vente :</b> obligatoire</span><br>
                        <span class="span-sec7"><b>Location :</b> optionnel</span><br>
                        <span class="span-sec7"><b>Durée de validité :</b> 6 mois</span>
                       
                    </div>
                    <p class="sec7-p">L'état relatif à la présence de termites, également appelé diagnostic termites, donne des informations sur la présence ou non d'insectes xylophages (termites : Insectes qui rongent le bois et les matériaux contenant de la cellulose en particulier) dans un logement. Ce document doit être réalisé lorsque le logement est situé dans une zone...</p>
                    <a routerLink="/termites" class="a-sec7">En savoir plus &rarr;</a>

                </div>
            </div>

            <div class="item">
                <div class="single-feedback-item">
                    <img class="img-sec7" src="/assets/image/h.webp">
                    <div class="div-h4-sec7">
                        <h4>Diagnostic Installation électricité</h4>


                        <span class="span-sec7"><b>Vente :</b> obligatoire</span><br>
                        <span class="span-sec7"><b>Location :</b> obligatoire</span><br>
                        <span class="span-sec7"><b>Durée de validité :</b> 3 ans pour la vente et 6 ans pour la location</span>
                       
                    </div>
                    <p class="sec7-p">L'état de l'installation intérieure d’électricité, aussi appelé diagnostic électricité, est un diagnostic qui donne un aperçu de la sécurité des installations électriques dans les logements. Votre logement est concerné par ce diagnostic si l'installation d'électricité a plus de 15 ans. Le diagnostic doit être...</p>
                    <a routerLink="/électricité"  class="a-sec7">En savoir plus &rarr;</a>

                </div>
            </div>

            <div class="item">
                <div class="single-feedback-item">
                    <img class="img-sec7" src="/assets/image/habitableee (2).webp">
                    <div class="div-h4-sec7">
                        <h4>Diagnostic Installation gaz</h4>


                        <span class="span-sec7"><b>Vente :</b> obligatoire</span><br>
                        <span class="span-sec7"><b>Location :</b> obligatoire</span><br>
                        <span class="span-sec7"><b>Durée de validité :</b> 3 ans pour la vente et 6 ans pour la location</span>
                       
                    </div>
                    <p class="sec7-p">L'état de l'installation intérieure de gaz, aussi appelé diagnostic gaz, est un diagnostic qui donne un aperçu de la sécurité des installations de gaz dans les logements. Votre logement est concerné si l'installation de gaz a plus de 15 ans. Le diagnostic doit être intégré au dossier de diagnostic...</p>
                    <a routerLink="/gaz" class="a-sec7">En savoir plus &rarr;</a>

                </div>
            </div>
        </div>


        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-feedback-item">
                    <img class="img-sec7" src="/assets/image/habitableee.webp">
                    <div class="div-h4-sec7">
                        <h4>Diagnostic Plomb (Crep)</h4>


                        <span class="span-sec7"><b>Vente :</b> obligatoire pour les constructions avant 1949</span><br>
                        <span class="span-sec7"><b>Location :</b> obligatoire pour les constructions avant 1949</span><br>
                        <span class="span-sec7"><b>Durée de validité :</b>  Illimité si abscence de plomb. En cas de présence, il est valable 1 an pour la vente et 6 ans pour la location
                        </span>
                       
                    </div>
                    <p class="sec7-p">Le constat de risque d'exposition au plomb (Crep), aussi appelé diagnostic plomb, est un document qui donne des informations sur la présence de plomb dans les logements...</p>
                    <a routerLink="/plomb" class="a-sec7">En savoir plus &rarr;</a>

                </div>
            </div>

            <div class="item">
                <div class="single-feedback-item">
                    <img class="img-sec7" src="/assets/image/habitablee.webp">
                    <div style="text-align: left;">
                        <h4>Diagnostic Métrage (Loi Boutin)</h4>


                        <span class="span-sec7"><b>Vente :</b> obligatoire pour les biens en copropriété</span><br>
                        <span class="span-sec7"><b>Location :</b> optionnel</span><br>
                        <span class="span-sec7"><b>Durée de validité :</b>  Illimité, sauf travaux de réagencement
                        </span>
                       
                    </div>
                    <p class="sec7-p">Le calcul de la surface habitable d’une maison ou d’un appartement fait partie des diagnostics obligatoires avant la signature d’un contrat de location...</p>
                    <a routerLink="/metrage" class="a-sec7">En savoir plus &rarr;</a>

                </div>
            </div>

            <div class="item">
                <div class="single-feedback-item">
                    <img class="img-sec7" src="/assets/image/hab.webp">
                    <div  style="text-align: left;">
                        <h4>Diagnostic risques et pollutions (ERP)</h4>


                        <span class="span-sec7"><b>Vente :</b> obligatoire</span><br>
                        <span class="span-sec7"><b>Location :</b> obligatoire</span><br>
                        <span class="span-sec7"><b>Durée de validité :</b> 6 mois
                        </span>
                       
                    </div>
                    <p class="sec7-p">Un diagnostic 'état des risques et pollutions' doit être joint à la promesse de vente ou à l'acte de vente ou au bail dans les communes concernées.</p>
                    <a routerLink="/erp" class="a-sec7">En savoir plus &rarr;</a>

                </div>
            </div>
</div>
        <a class="a-sec7-alldiag" routerLink="/diagnostics-immobiliers-obligatoires">voir tous les diagnostics &rarr;</a>


    </div>

    <div class="shape-circle-img2">
        <img src="assets/img/shape-img2.png" alt="image">
    </div>
</section>
<!-- end section 7 -->
 



<!-- start section 8 -->


<div class="uk-container cont3" id="uk-">

    <div class="div-sec8-grid-v">
        <img class="img-sec8"  src="/assets/image/Audit_energétique.png">
        
        <h3 class="h3-sec8">Audit énergétique</h3>
        
        <p class="sec8-p">
            Un audit énergétique est un outil indispensable pour identifier les sources de gaspillage énergétique dans un bâtiment ou un logement. En évaluant la performance énergétique d'un bien immobilier, il permet de repérer les zones de consommation excessive et de proposer des solutions pour réduire les coûts énergétiques.
        </p>
        
        <a routerLink="/audit-energetique" class="uk-button uk-button-default sec8-btn">En savoir plus</a>
    </div>
    
    <div class="div-sec8-grid-v">
        <img class="img-sec8" src="/assets/image/Amiante avant travaux.png">
        
        <h3 class="h3-sec8">Amiante avant travaux</h3>
        
        <p class="sec8-p">
            Notre service de 'Diagnostic Amiante Avant Travaux' est essentiel pour garantir la sécurité lors de travaux de rénovation ou de démolition. Nous effectuons des inspections approfondies pour détecter toute présence d'amiante dans les matériaux de construction, permettant ainsi de prendre des mesures préventives appropriées pour protéger la santé des travailleurs et de l'environnement.
        </p>
        
        <a routerLink="/amiante-avant-travaux" class="uk-button uk-button-default sec8-btn">En savoir plus</a>
    </div>

</div>