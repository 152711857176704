<section class="page-title-area uk-section uk-funfacts sec11">
  <div class="overlay"></div>
  <div class="uk-container">
      <div class="text-content">
          <h1 class="h1-sec11">Obtenez un devis rapide</h1>
          <h5><p class="p-sec11">DIAGNOSTICS IMMOBILIERS À PARIS ET DANS TOUTE LA FRANCE</p></h5>
          <a routerLink="/une-question" class="uk-button uk-button-default">UNE QUESTION ?</a>
      </div>
  </div>
</section>

<!-- Start Contact Area -->
<section id="contact" class="contact-area uk-contact uk-section">
  <div class="uk-container">
      <div class="item">
          <div class="uk-section-title section-title">
              <h3>Audit énergétique</h3>
              <span>{{step}}/4</span>
                  
      <!-- Progress Bar -->
      <div class="progress-bar">
          <div class="progress-step active" [style.width]="progressWidth + '%'"></div>
        
      </div>
      
          <p *ngIf="step===1">Votre projet</p>
          <p  *ngIf="step===2">Diagnostics</p>
          <p  *ngIf="step===3">Informations personnelles</p>
          <p  *ngIf="step===4">Votre panier</p>



      </div>
      <div id="contactForm" *ngIf="step === 1">
          <h6 class="h6-project"><span class="span-project">1</span> Votre projet</h6>
          <div class="message-box">
              <img src="/assets/image/information-button.png" style="transform: translate(-30%,-40%);width: 20px;">
              Afin d'obtenir un devis « audit énergétique », veuillez svp répondre aux questions suivantes
          </div>

          <div id="contactForm">
              <h5>Quel est votre objectif ?</h5>
              <div class="objective-buttons">
                  <a class="objective-button" 
                     [ngClass]="{'active': selectedObjectives['objective'] === 'Location'}" 
                     (click)="setActive('objective', 'Location')">
                    Location <span *ngIf="selectedObjectives['objective'] === 'Location'" class="check-icon">✔</span>
                  </a>
                  <a class="objective-button" 
                     [ngClass]="{'active': selectedObjectives['objective'] === 'Vente'}" 
                     (click)="setActive('objective', 'Vente')">
                    Vente <span *ngIf="selectedObjectives['objective'] === 'Vente'" class="check-icon">✔</span>
                  </a>
                  <a class="objective-button" 
                  [ngClass]="{'active': selectedObjectives['objective'] === 'Audit incitatif'}" 
                  (click)="setActive('objective', 'Audit incitatif')">
                  Audit incitatif <span *ngIf="selectedObjectives['objective'] === 'Audit incitatif'" class="check-icon">✔</span>
               </a>
                </div>
                <div *ngIf="showError2" class="error">
                  <small>Veuillez sélectionner un objectif : Location ou Vente ou Audit incitatif.</small>
                </div>
              <h5>Quelle est l'adresse du bien ?</h5>
              <div class="input-containerr">
                  <img src="/assets/image/location.png" alt="Icon" class="input-icon" />
                  <input type="text" id="uk-input" class="uk-input" placeholder="Saisisser l'adresse du bien" [(ngModel)]="selectedObjectives['Adresse du bien']" 
                  (ngModelChange)="setActive('Adresse du bien', $event)"
                  #adresseDuBien="ngModel" 
                  required>
      
</div>
<div *ngIf="adresseDuBien.invalid && (adresseDuBien.dirty || adresseDuBien.touched)" class="error">
  <small *ngIf="adresseDuBien.errors?.required">L'adresse du bien est obligatoire.</small>
</div>
              <h5>Code postal</h5>
              <div class="input-containerr">
              <input type="text" class="uk-input" placeholder="Veuiller saisir votre code postal" [(ngModel)]="selectedObjectives['Code postal']" 
              (ngModelChange)="setActive('Code postal', $event)"   #commune="ngModel" 
              required pattern="^[0-9]{5}$">
  </div>
  <div *ngIf="commune.invalid && (commune.dirty || commune.touched)" class="error">
  <small *ngIf="commune.errors?.required">Le code postal est obligatoire.</small>
  <small *ngIf="commune.errors?.pattern">Le code postal doit être composé de 5 chiffres.</small>
  </div>
              <h5>Quel est le type du bien ?</h5>

           
          
              <div class="property-types">
                  <!-- Display only "Maison" if "Audit incitatif" is selected -->
                  <div *ngIf="selectedObjectives['objective'] === 'Audit incitatif'" class="property-type objective-button" 
                       [ngClass]="{'active': selectedObjectives['Type du bien'] === 'Maison'}" (click)="setActive('Type du bien','Maison')">
                      <img src="/assets/image/maison.png"><br> Maison 
                      <span *ngIf="selectedObjectives['Type du bien'] === 'Maison'" class="check-icon">✔</span>
                  </div>

                  <!-- Display all property types if "Audit incitatif" is NOT selected -->
                  <ng-container *ngIf="selectedObjectives['objective'] !== 'Audit incitatif'">
                      <div class="property-type objective-button" 
                      [ngClass]="{'active': selectedObjectives['Type du bien'] === 'Appartement'}" (click)="setActive('Type du bien','Appartement')">                             
                          <img src="/assets/image/appartement.png"><br> Appartement 
                          <span *ngIf="selectedObjectives['Type du bien'] === 'Appartement'" class="check-icon">✔</span>
                        </div>
                      
                      <div class="property-type objective-button" 
                      [ngClass]="{'active': selectedObjectives['Type du bien'] === 'Maison'}" (click)="setActive('Type du bien','Maison')">                             
                          <img src="/assets/image/maison.png"><br> Maison 
                          <span *ngIf="selectedObjectives['Type du bien'] === 'Maison'" class="check-icon">✔</span>
                      </div>
                      
                      <div class="property-type objective-button" 
                      [ngClass]="{'active': selectedObjectives['Type du bien'] === 'Immeuble collectif'}" (click)="setActive('Type du bien','Immeuble collectif')">                             

                          <img src="/assets/image/immeuble.png"><br> Immeuble collectif 
                          <span *ngIf="selectedObjectives['Type du bien'] === 'Immeuble collectif'" class="check-icon">✔</span>
                      </div>
                  </ng-container>
              </div>
              <div *ngIf="showError2" class="error">
                <small>Ce champ est obligatoire.</small>
              </div>
              <h5>Surface du bien (approx. en m²)</h5>
              <div class="input-container">
                  <img src="/assets/image/width.png" alt="Icon" class="input-icon" />
                  <input type="number" min="1" id="uk-inputt" class="uk-input" placeholder="80 par ex." [(ngModel)]="selectedObjectives['Surface du bien (approx. en m²)']" 
                  (ngModelChange)="setActive('Surface du bien (approx. en m²)', $event)" name="surfaceDuBien"    #surfaceDuBien="ngModel"
                  required>
           <div *ngIf="surfaceDuBien.invalid && (surfaceDuBien.dirty || surfaceDuBien.touched)" class="error">
             <small *ngIf="surfaceDuBien.errors?.required">La surface du bien est obligatoire.</small>
           </div>
</div>
         
            </div>
            <div id="error" *ngIf="showError2" class="error-message">
              <img src="/assets/image/exclamation.png" style="transform: translate(-30%,-10%);width: 20px;">
              Veuillez remplir tous les champs requis.
          </div>
          </div>



     

       
           <div id="contactForm" *ngIf="step === 2"  >

              <h6 class="h6-project"><span class="span-project">2</span> Diagnostics</h6>
                     
              <div class="message-box">
                <i class="fas fa-info-circle"></i>
                Les diagnostics obligatoires sont présélectionnés dans le panier.
              </div>
           
              <div  style="background-color: rgba(124, 169, 208, 0.197); border: 2px solid rgba(0, 136, 255, 0.367); border-radius: 3px; padding: 15px;">
                 <div style="display: flex; align-items: center; justify-content: space-between; width: 100%;">
                    <h6>
                       <i style="margin-right: 15px;" class="fa fa-shopping-cart" aria-hidden="true"></i>
                       Ma sélection ({{1}})
                    </h6>
                   
                 </div>
                       
                    <div>
                      <div class="item-container">
                       <img src="/assets/image/audit-01.webp" width="50" style="margin-right: 10px; border-radius: 4px;">
           
                       <div class="item-details">
                        <span class="item-name">Audit énergétique</span> <br>
                     

                        <div class="qnt-div"> <span class="qnt-span"> Quantité: 1</span></div>
                      </div>
                       <hr style="border: 1px solid rgba(33, 72, 202, 0.068);">

                    </div>
                 </div>
              </div>
              
              <div class="diagno">
                 <div class="faq-item">
                    <div class="faq-question" (click)="toggleAnswer('1')">
                       <span>{{ isOpen['1'] ? '↓' : '→' }}</span>
                       <img src="/assets/image/audit-01.webp" width="60" style="margin-right: 10px; border-radius: 4px;">
                       <div style="display: flex; align-items: center; justify-content: space-between; width: 100%;">
                          Risques et pollutions (ERP)
                          <a  class="add-btn disabled">Ajouter</a>
                       </div>
                    </div>
                    <div class="faq-answer" *ngIf="isOpen['1']">
                       <hr>
                       <p>Un audit énergétique est un outil indispensable pour identifier les sources de gaspillage énergétique dans un bâtiment ou un logement. En évaluant la performance énergétique d'un bien immobilier, il permet de repérer les zones de consommation excessive et de proposer des solutions pour réduire les coûts énergétiques</p>
                    </div>
                 </div>
           </div>
    
           </div>
         

           
           <div id="contactForm" *ngIf="step === 3"  >

            <h6 class="h6-project"><span class="span-project">3</span> Informations personnelles</h6>
                    
                       <div class="message-box">
                          <i class="fas fa-info-circle"></i>
                          Afin de recevoir votre devis, veuillez svp remplir les champs suivants et répondre aux questions éventuelles
                        </div>
                    
                    
                        <h5>Êtes-vous un(e)... </h5>
                        <div class="objective-buttons">
                          <a class="objective-button" 
                             [ngClass]="{'active': selectedObjectives2['Type de donneur d ordre'] === 'particulier (propriétaire du bien)'}" 
                             (click)="setActive('Type de donneur d ordre', 'particulier (propriétaire du bien)')">
                             particulier (propriétaire du bien) <span *ngIf="selectedObjectives2['Type de donneur d ordre'] === 'particulier (propriétaire du bien)'" class="check-icon">✔</span>
                          </a>
                          <a class="objective-button" 
                             [ngClass]="{'active': selectedObjectives2['Type de donneur d ordre'] === 'société (propriétaire du bien)'}" 
                             (click)="setActive('Type de donneur d ordre', 'société (propriétaire du bien)')">
                             société (propriétaire du bien) <span *ngIf="selectedObjectives2['Type de donneur d ordre'] === 'société (propriétaire du bien)'" class="check-icon">✔</span>
                          </a>
                          <a class="objective-button" 
                             [ngClass]="{'active': selectedObjectives2['Type de donneur d ordre'] === 'professionnel mandaté par le propriétaire'}" 
                             (click)="setActive('Type de donneur d ordre', 'professionnel mandaté par le propriétaire')">
                             professionnel mandaté par le propriétaire <span *ngIf="selectedObjectives2['Type de donneur d ordre'] === 'professionnel mandaté par le propriétaire'" class="check-icon">✔</span>
                          </a>
                        </div>
                    
                    
          
                   
                      
                              <div class="item">
                            
                                
                                   <div *ngIf="selectedObjectives2['Type de donneur d ordre'] === 'professionnel mandaté par le propriétaire'">                       
                    
                                       <div id="cordonee" >
                         
                                           
                                         <div class="form-row">
                                           <div class="input-group">
                                             <label for="prenom">Nom de votre société (intermédiaire)</label>
                                             <input type="text" placeholder="Saisir le nom  de votre société" class="uk-input" [(ngModel)]="selectedObjectives2['Nom de votre société (intermédiaire)']" 
                                             (ngModelChange)="setActive('Nom de votre société (intermédiaire)', $event)">                              
                                           </div>
                              
                            
                                       
                                         </div>
                                       </div>
                         
                                       </div>
                                   <div *ngIf="selectedObjectives2['Type de donneur d ordre'] === 'société (propriétaire du bien)'">
                                      <h6>Société </h6>
                       
                                     <div id="cordonee" >
                       
                                       <div class="form-row">
                                         <div class="input-group">
                                           <label for="prenom">Nom de votre société</label>
                                           <input type="text" id="nom_societe"  placeholder="Saisir le nom de votre société" required  [(ngModel)]="selectedObjectives2['Nom de votre société']" 
                                           (ngModelChange)="setActive('Nom de votre société', $event)">
                                           
                                         </div>
                            
                          
                                     
                                       </div>
                                     </div>
                       
                                     </div>
                           
                                    
                                   <div >
                                  
                                     <div>
                                        
                                     </div>
                                      <h6 *ngIf="selectedObjectives2['Type de donneur d ordre'] === 'particulier (propriétaire du bien)'" >
                                         Vos coordonnées
                                       </h6>
                    
                                       <h6 *ngIf="selectedObjectives2['Type de donneur d ordre'] === 'société (propriétaire du bien)'" >
                                         Contact principal
                                      </h6>
                                       
                                       
                                       
                    
                                      <div *ngIf="selectedObjectives2['Type de donneur d ordre'] === undefined ">
                                                    </div> 
                                                      
                                  <div id="cordonee" *ngIf="selectedObjectives2['Type de donneur d ordre'] !=undefined ">
                    
                                    <div class="form-row">
                                      <div class="input-group">
                                        <label for="prenom">Prénom</label>
                                        <input type="text" id="prenom"  placeholder="Saisir votre prénom" required  [(ngModel)]="selectedObjectives2['Prénom']" 
                                        (ngModelChange)="setActive('Prénom', $event)">
                                        <div *ngIf="formControls.prenom.invalid && (formControls.prenom.dirty || formControls.prenom.touched)">
                                          <small *ngIf="formControls.prenom.errors?.['required']">Prénom is required.</small>
                                        </div>
                                      </div>
                        
                                      <div class="input-group">
                                        <label for="nom">Nom</label>
                                        <input type="text" id="nom"  placeholder="Saisir votre nom" required [(ngModel)]="selectedObjectives2['Nom']" 
                                        (ngModelChange)="setActive('Nom', $event)">
                                        <div *ngIf="formControls.nom.invalid && (formControls.nom.dirty || formControls.nom.touched)">
                                          <small *ngIf="formControls.nom.errors?.['required']">Nom is required.</small>
                                        </div>
                                      </div>
                                    </div>
                        
                                    <div class="form-row">
                                      <div class="input-group">
                                        <label for="email">Email</label>
                                        <input type="email" id="email"  placeholder="Saisir votre email" required [(ngModel)]="selectedObjectives2['Email']" 
                                        (ngModelChange)="setActive('Email', $event)">
                                        <div *ngIf="formControls.email.invalid && (formControls.email.dirty || formControls.email.touched)">
                                          <small *ngIf="formControls.email.errors?.['required']">Email is required.</small>
                                          <small *ngIf="formControls.email.errors?.['email']">Please enter a valid email address.</small>
                                        </div>
                                      </div>
                        
                                      <div class="input-group">
                                        <label for="telephone">Téléphone</label>
                                        <input type="tel" id="telephone"  placeholder="Saisir votre téléphone" required [(ngModel)]="selectedObjectives2['Téléphone']" 
                                        (ngModelChange)="setActive('Téléphone', $event)">
                                        <div *ngIf="formControls.telephone.invalid && (formControls.telephone.dirty || formControls.telephone.touched)">
                                          <small *ngIf="formControls.telephone.errors?.['required']">Téléphone is required.</small>
                                        </div>
                                      </div>
                                    </div>
                    
                    
                    
                               
                                  </div>
                             
                            
                    
                                  </div>
                    
                              
                    
                                
                    
                                  <div  *ngIf="selectedObjectives2['Type de donneur d ordre'] === 'professionnel mandaté par le propriétaire'">
                                   <h5 >
                                      Votre client est un(e)....</h5>
                                   <div class="objective-buttonse" >
                                       <a class="objective-button" [ngClass]="{'active': selectedObjectives2['Votre client est un(e)'] === 'particulier'}" (click)="setActive('Votre client est un(e)','particulier')">
                                           particulier <span *ngIf="selectedObjectives2['Votre client est un(e)'] === 'particulier'" class="check-icon">✔</span>
                                       </a>
                                       <a class="objective-button" [ngClass]="{'active': selectedObjectives2['Votre client est un(e)'] === 'Société'}" (click)="setActive('Votre client est un(e)','Société')">
                                           société <span *ngIf="selectedObjectives2['Votre client est un(e)'] === 'Société'" class="check-icon">✔</span>
                                       </a>
        
                                       </div>
                                   </div>
                                   <div *ngIf="selectedObjectives2['Votre client est un(e)'] === 'Société' && selectedObjectives2['Type de donneur d ordre'] === 'professionnel mandaté par le propriétaire' "  >
                                      <div id="pre"> <label>Société</label>
                                       <div id="cordoneer" >
                                     
                                           <div class="form-row">
                                             <div class="input-group">
                                               <label for="prenom">Nom de votre société (client)</label>
                                               <input type="text" placeholder="Nom de votre société (client)" class="uk-input" [(ngModel)]="selectedObjectives2['Nom de votre société (client)']" 
                                               (ngModelChange)="setActive('Nom de votre société (client)', $event)">                               
                                             </div>
                                           </div>
                                  </div>
                                  </div>
                                  <label for="prenom">Contact principal</label>
                                       <div id="cordonee" >
                                           
        
                                            <div class="form-row">
                                              <div class="input-group">
                                                <label for="prenom">Nom du contact principal</label>
                                                <input type="text" id="prenom"  placeholder="Saisir votre prénom" required [(ngModel)]="selectedObjectives2['Nom du contact principal']" 
                                                (ngModelChange)="setActive('Nom du contact principal', $event)">
                                                <div *ngIf="formControls.prenom.invalid && (formControls.prenom.dirty || formControls.prenom.touched)">
                                                  <small *ngIf="formControls.prenom.errors?.['required']">Prénom is required.</small>
                                                </div>
                                              </div>
                                
                                           
                                            </div>
                                
                                            <div class="form-row">
                                              <div class="input-group">
                                                <label for="email">Email</label>
                                                <input type="email" id="email"  placeholder="Saisir votre email" required [(ngModel)]="selectedObjectives2['Email de contact principal']" 
                                                (ngModelChange)="setActive('Email de contact principal', $event)">
                                                <div *ngIf="formControls.email.invalid && (formControls.email.dirty || formControls.email.touched)">
                                                  <small *ngIf="formControls.email.errors?.['required']">Email is required.</small>
                                                  <small *ngIf="formControls.email.errors?.['email']">Please enter a valid email address.</small>
                                                </div>
                                              </div>
                                
                                              <div class="input-group">
                                                <label for="telephone">Téléphone</label>
                                                <input type="tel" id="telephone"  placeholder="Saisir votre téléphone" required  [(ngModel)]="selectedObjectives2['Téléphone de contact principal']" 
                                                (ngModelChange)="setActive('Téléphone de contact principal', $event)">
                                                <div *ngIf="formControls.telephone.invalid && (formControls.telephone.dirty || formControls.telephone.touched)">
                                                  <small *ngIf="formControls.telephone.errors?.['required']">Téléphone is required.</small>
                                                </div>
                                              </div>
                                            </div>
                            
                            
                            
                                       
                                          </div>
                                       </div>
                                       <div *ngIf="selectedObjectives2['Votre client est un(e)'] === 'particulier' && selectedObjectives2['Type de donneur d ordre'] === 'professionnel mandaté par le propriétaire'">
                                     
                                       <label for="prenom">Client</label>
                                            <div id="cordonee" >
                                                
                                                 <div class="form-row">
                                                   <div class="input-group">
                                                     <label for="prenom">Prénom du client</label>
                                                     <input type="text" id="prenom"  placeholder="Saisir votre prénom" required  [(ngModel)]="selectedObjectives2['Prénom du client']" 
                                                     (ngModelChange)="setActive('Prénom du client', $event)">
                                                     <div *ngIf="formControls.prenom.invalid && (formControls.prenom.dirty || formControls.prenom.touched)">
                                                       <small *ngIf="formControls.prenom.errors?.['required']">Prénom is required.</small>
                                                     </div>
                                                   </div>
                                     
                                                   <div class="input-group">
                                                     <label for="nom">Nom du client</label>
                                                     <input type="text" id="nom"  placeholder="Saisir votre nom" required [(ngModel)]="selectedObjectives2['Nom du client']" 
                                                     (ngModelChange)="setActive('Nom du client', $event)">
                                                     <div *ngIf="formControls.nom.invalid && (formControls.nom.dirty || formControls.nom.touched)">
                                                       <small *ngIf="formControls.nom.errors?.['required']">Nom is required.</small>
                                                     </div>
                                                   </div>
                                                 </div>
                                     
                                                 <div class="form-row">
                                                   <div class="input-group">
                                                     <label for="email">Email</label>
                                                     <input type="email" id="email"  placeholder="Saisir votre email" required [(ngModel)]="selectedObjectives2['Email de client']" 
                                                     (ngModelChange)="setActive('Email de client', $event)">
                                                     <div *ngIf="formControls.email.invalid && (formControls.email.dirty || formControls.email.touched)">
                                                       <small *ngIf="formControls.email.errors?.['required']">Email is required.</small>
                                                       <small *ngIf="formControls.email.errors?.['Email de client']">Please enter a valid email address.</small>
                                                     </div>
                                                   </div>
                                     
                                                   <div class="input-group">
                                                     <label for="telephone">Téléphone</label>
                                                     <input type="tel" id="telephone"  placeholder="Saisir votre téléphone" required [(ngModel)]="selectedObjectives2['Téléphone de client']" 
                                                     (ngModelChange)="setActive('Téléphone de client', $event)">
                                                     <div *ngIf="formControls.telephone.invalid && (formControls.telephone.dirty || formControls.telephone.touched)">
                                                       <small *ngIf="formControls.telephone.errors?.['required']">Téléphone is required.</small>
                                                     </div>
                                                   </div>
                                                 </div>
                                 
                                 
                                 
                                            
                                               </div>
                                            </div>
        
                                         
                                  <h5>Commentaire</h5>
                                  <textarea id="message"  rows="4" placeholder="Saisir votre commentaire" required [(ngModel)]="selectedObjectives2['Commentaire']" 
                                  (ngModelChange)="setActive('Commentaire', $event)">
                                     
                                  </textarea>
                                 
                              
                                  
                                   
                              </div>
                              <div id="error" *ngIf="errorMessage" class="error-message">
                                <img src="/assets/image/exclamation.png" style="transform: translate(-30%,-10%);width: 20px;"> {{ errorMessage }}
                              </div>
          
          </div>

                   
              <div id="contactForm" *ngIf="step === 4"  >
                <h6 class="h6-project"><span class="span-project">4</span> Votre panier</h6>
                          
                <div class="message-box">
                   <i class="fas fa-info-circle"></i>
                   Veuillez svp vérifier les détails de votre panier avant de passer à la dernière étape.
                 </div>
              
               
                
                 <h6 >Détails du panier</h6>
              
              
                 <div class="panierr">
              
               <div>
                <a class="modf-panier" (click)="Modifier('Votre projet')">Modifier</a>
              
                   <span class="panier-details-span"><img class="icon-panier" src="/assets/image/panier.png">
              
              
                      Votre projet</span>
              
                      <ul>
                         <li class="li-step5" *ngFor="let item of selectedObjectives | keyvalue">
                          <span class="key">{{ item.key }} :</span> <span>{{ item.value }}</span> 
                         </li>
                       </ul>
                       
              
              
                 </div>
                <hr>
              
                
              
                    <div>
                      <a class="modf-panier" (click)="Modifier('Informations personnelle')">Modifier</a>
                   
                         <span class="panier-details-span"> <img class="icon-panier" src="/assets/image/panier.png">
                       Informations personnelle</span>
                   
                         <ul>
                            <li class="li-step5" *ngFor="let item of selectedObjectives2 | keyvalue">
                               <span class="key">{{ item.key }} :</span> <span>{{ item.value }}</span> 
                
                
                            </li>
                
                         </ul>
                   
                   
                       </div>
              
                 </div>
              
              
                 <div class="panierr">
                   <div class="panier-content">
                      <h6>
              
                         <i class="fa fa-shopping-cart shop-i" aria-hidden="true"></i>
                         <span class="panier-details-span">Ma sélection (1)</span>
              
                      </h6>
              
                      <a class="modf-panier" (click)="Modifier('Panier')">Modifier</a>
                   </div>
              
                   <div class="item-container">
                         
                      <img src="/assets/image/audit-01.webp" width="50" class="img-panier">
                      <div class="item-details">
                     <span class="item-name">Audit énergétique</span> <br>
                      <div class="qnt-div"> <span class="qnt-span"> Quantité: 1</span></div>
                      </div>
                   </div>
                   
                </div>
              
              
              
              </div>

             
              <div class="button-group">
              <div> <button type="button" class="uk-button uk-button-primary" (click)="goBack()" [disabled]="step === 1" >Précédent</button></div> 
                <button type="button" class="uk-button uk-button-primary" *ngIf="step<=3" (click)="nextStep()" >Continuer</button>
                <button *ngIf="step===4" type="button" class="uk-button uk-button-primary" (click)="sendMail()">Demander un devis</button>

            </div>
                  
      </div>
  </div>
</section>
<!-- End Contact Area -->