<section class="page-title-areaa uk-section uk-funfacts sec11">
    <div class="overlay"></div>

    <div class="uk-container">
        <div class="text-content">
            <h1 class="h1-sec11">S.A Diag</h1>
        <h5><p class="p-sec11">DIAGNOSTICS IMMOBILIERS À PARIS ET DANS TOUTE LA FRANCE</p></h5>
            <div class="button-container">
            </div>
        </div>
    </div>
</section>

<!-- start section 7 -->
<section id="testimonials" class="feedback-section uk-process uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-text-center">
            <h2>Les diagnostics immobiliers obligatoires</h2>
           <p>Pour vos diagnostics immobiliers obligatoires, obtenez un devis et un prix en ligne pour la vente ou location de votre maison et appartement</p> 

            <div class="bar"></div>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-feedback-item">
                    <img class="img-sec7" src="/assets/image/DPE.webp">
                    <div class="div-h4-sec7">
                        <h4>Diagnostic Performance énergétique (DPE)</h4>


                        <span class="span-sec7"><b>Vente :</b> obligatoire</span><br>
                        <span class="span-sec7"><b>Location :</b> obligatoire</span><br>
                        <span class="span-sec7"><b>Durée de validité :</b> 10 ans</span>
                       
                    </div>
                    <p class="sec7-p">Le DPE sert principalement à évaluer la quantité d'énergie et de gaz à effet de serre de votre logement. Vous devez faire réaliser ce diagnostic par un professionnel certifié. Vous devez intégrer le DPE au dossier de diagnostic technique (DDT) et le remettre à l'acquéreur ou au locataire lorsque vous vendez ou louez votre logement.</p>
  
                    <a routerLink="/PerformanceDPE" class="a-sec7">En savoir plus &rarr;</a>
            </div>
            </div>

            <div class="item">
                <div class="single-feedback-item">
                    <img class="img-sec7" src="/assets/image/habita.webp" width="90">
                    <div class="div-h4-sec7">
                        <h4>Diagnostic Amiante</h4>
                        <span class="span-sec7"><b>Vente :</b> obligatoire pour les constructions avant 1997</span><br>
                        <span class="span-sec7"><b>Location :</b> obligatoire pour les constructions avant 1997</span><br>
                        <span class="span-sec7"><b>Durée de validité :</b> Illimité si abscence d'amiante. Il est conseillé de réaliser un diagnostic amiante avant chaque vente.</span>
                    </div>
                    <p class="sec7-p">
                        L'état d'amiante, aussi appelé diagnostic amiante, est un document qui mentionne la présence ou l'absence de matériaux ou produits contenant de l'amiante dans un
                    </p>
                    <a routerLink="/amiante" class="a-sec7">En savoir plus &rarr;</a>
                </div>
            </div>
            

            <div class="item">
                <div class="single-feedback-item">
                    <img class="img-sec7" src="/assets/image/habitablee.webp">
                    <div class="div-h4-sec7">
                        
                        <h4>Diagnostic Métrage (Loi Carrez)</h4>
                        <span class="span-sec7"><b>Vente :</b>  obligatoire pour les biens en copropriété</span><br>
                        <span class="span-sec7"><b>Location :</b> optionnel</span><br>
                        <span class="span-sec7"><b>Durée de validité :</b>  Illimité, sauf travaux de réagencement</span>
                       
                    </div>
                    <p class="sec7-p">Le calcul de la surface habitable d’une maison ou d’un appartement fait partie des diagnostics obligatoires avant la signature d’un contrat de vente. Il sera exigé à la signature du compromis de vente. Il doit être réalisé par un diagnostiqueur formé et...</p>
                    
                    <a routerLink="/carrez" class="a-sec7">En savoir plus &rarr;</a>
                </div>
            </div>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-feedback-item">
                    <img class="img-sec7" src="/assets/image/gg.webp">
                    <div class="div-h4-sec7">
                        <h4>Diagnostic Termites</h4>


                        <span class="span-sec7"><b>Vente :</b> obligatoire</span><br>
                        <span class="span-sec7"><b>Location :</b> optionnel</span><br>
                        <span class="span-sec7"><b>Durée de validité :</b> 6 mois</span>
                       
                    </div>
                    <p class="sec7-p">L'état relatif à la présence de termites, également appelé diagnostic termites, donne des informations sur la présence ou non d'insectes xylophages (termites : Insectes qui rongent le bois et les matériaux contenant de la cellulose en particulier) dans un logement. Ce document doit être réalisé lorsque le logement est situé dans une zone...</p>
                    <a routerLink="/termites" class="a-sec7">En savoir plus &rarr;</a>

                </div>
            </div>

            <div class="item">
                <div class="single-feedback-item">
                    <img class="img-sec7" src="/assets/image/h.webp">
                    <div class="div-h4-sec7">
                        <h4>Diagnostic Installation électricité</h4>


                        <span class="span-sec7"><b>Vente :</b> obligatoire</span><br>
                        <span class="span-sec7"><b>Location :</b> obligatoire</span><br>
                        <span class="span-sec7"><b>Durée de validité :</b> 3 ans pour la vente et 6 ans pour la location</span>
                       
                    </div>
                    <p class="sec7-p">L'état de l'installation intérieure d’électricité, aussi appelé diagnostic électricité, est un diagnostic qui donne un aperçu de la sécurité des installations électriques dans les logements. Votre logement est concerné par ce diagnostic si l'installation d'électricité a plus de 15 ans. Le diagnostic doit être...</p>
                    <a routerLink="/électricité"  class="a-sec7">En savoir plus &rarr;</a>

                </div>
            </div>

            <div class="item">
                <div class="single-feedback-item">
                    <img class="img-sec7" src="/assets/image/habitableee (2).webp">
                    <div class="div-h4-sec7">
                        <h4>Diagnostic Installation gaz</h4>


                        <span class="span-sec7"><b>Vente :</b> obligatoire</span><br>
                        <span class="span-sec7"><b>Location :</b> obligatoire</span><br>
                        <span class="span-sec7"><b>Durée de validité :</b> 3 ans pour la vente et 6 ans pour la location</span>
                       
                    </div>
                    <p class="sec7-p">L'état de l'installation intérieure de gaz, aussi appelé diagnostic gaz, est un diagnostic qui donne un aperçu de la sécurité des installations de gaz dans les logements. Votre logement est concerné si l'installation de gaz a plus de 15 ans. Le diagnostic doit être intégré au dossier de diagnostic...</p>
                    <a routerLink="/gaz" class="a-sec7">En savoir plus &rarr;</a>

                </div>
            </div>
        </div>


        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-feedback-item">
                    <img class="img-sec7" src="/assets/image/habitableee.webp">
                    <div class="div-h4-sec7">
                        <h4>Diagnostic Plomb (Crep)</h4>


                        <span class="span-sec7"><b>Vente :</b> obligatoire pour les constructions avant 1949</span><br>
                        <span class="span-sec7"><b>Location :</b> obligatoire pour les constructions avant 1949</span><br>
                        <span class="span-sec7"><b>Durée de validité :</b>  Illimité si abscence de plomb. En cas de présence, il est valable 1 an pour la vente et 6 ans pour la location
                        </span>
                       
                    </div>
                    <p class="sec7-p">Le constat de risque d'exposition au plomb (Crep), aussi appelé diagnostic plomb, est un document qui donne des informations sur la présence de plomb dans les logements...</p>
                    <a routerLink="/plomb" class="a-sec7">En savoir plus &rarr;</a>

                </div>
            </div>

            <div class="item">
                <div class="single-feedback-item">
                    <img class="img-sec7" src="/assets/image/habitablee.webp">
                    <div style="text-align: left;">
                        <h4>Diagnostic Métrage (Loi Boutin)</h4>


                        <span class="span-sec7"><b>Vente :</b> obligatoire pour les biens en copropriété</span><br>
                        <span class="span-sec7"><b>Location :</b> optionnel</span><br>
                        <span class="span-sec7"><b>Durée de validité :</b>  Illimité, sauf travaux de réagencement
                        </span>
                       
                    </div>
                    <p class="sec7-p">Le calcul de la surface habitable d’une maison ou d’un appartement fait partie des diagnostics obligatoires avant la signature d’un contrat de location...</p>
                    <a routerLink="/metrage" class="a-sec7">En savoir plus &rarr;</a>

                </div>
            </div>

            <div class="item">
                <div class="single-feedback-item">
                    <img class="img-sec7" src="/assets/image/hab.webp">
                    <div  style="text-align: left;">
                        <h4>Diagnostic risques et pollutions (ERP)</h4>


                        <span class="span-sec7"><b>Vente :</b> obligatoire</span><br>
                        <span class="span-sec7"><b>Location :</b> obligatoire</span><br>
                        <span class="span-sec7"><b>Durée de validité :</b> 6 mois
                        </span>
                       
                    </div>
                    <p class="sec7-p">Un diagnostic 'état des risques et pollutions' doit être joint à la promesse de vente ou à l'acte de vente ou au bail dans les communes concernées.</p>
                    <a routerLink="/erp" class="a-sec7">En savoir plus &rarr;</a>

                </div>
            </div>

            <div class="item">
                <div class="single-feedback-item">
                    <img class="img-sec7" src="/assets/image/27c32540-74b0-11ed-8e6c-09ead3a7d4d7.webp">
                    <div style="text-align: left;">
                        <h4>Diagnostic technique global (DTG)</h4>


                      
                    </div>
                    <p class="sec7-p">Le diagnostic technique global (DTG) informe les copropriétaires sur l'état de l'immeuble. Il permet d'envisager d'éventuels travaux. Il est obligatoire pour certaines copropriétés. Si le DTG démontre que des travaux sont nécessaires, le syndic doit inscrire à l'ordre du jour de l'assemblée générale la question de faire un plan pluriannuel de travaux.</p>
                    <a routerLink="/techniqueGlobal" class="a-sec7">En savoir plus &rarr;</a>

                </div>
            </div>

            <div class="item">
                <div class="single-feedback-item">
                    <img class="img-sec7" src="/assets/image/audit-01.webp">
                    <div style="text-align: left;">
                        <h4>Audit énergétique</h4>


                        <span class="span-sec7"><b>Vente :</b> obligatoire</span><br>
                        <span class="span-sec7"><b>Location :</b> optionnel</span><br>
                        <span class="span-sec7"><b>Durée de validité :</b> 10 ans
                        </span>
                      
                    </div>
                    <p class="sec7-p">Un audit énergétique est un outil indispensable pour identifier les sources de gaspillage énergétique dans un bâtiment ou un logement. En évaluant la performance énergétique d'un bien immobilier, il permet de repérer les zones de consommation excessive et de proposer des solutions pour réduire les coûts énergétiques</p>
                    <a routerLink="/audit-energetique" class="a-sec7">En savoir plus &rarr;</a>

                </div>
            </div>


            <div class="item">
                <div class="single-feedback-item">
                    <img class="img-sec7" src="/assets/image/partie-commune-01.webp">
                    <div style="text-align: left;">
                        <h4>Diagnostics parties communes</h4>


                    
                      
                    </div>
                    <p class="sec7-p">Notre produit "Diagnostics parties communes" est un service de diagnostic immobilier complet visant à identifier les risques liés à la présence d'amiante, de plomb et de termites dans les parties communes d'un immeuble. À la demande du syndic ou de la copropriété, nous réalisons des analyses approfondies pour vous permettre de garantir la sécurité et la santé de tous les occupants de l'immeuble.</p>
                    <a routerLink="/partiesCommunes" class="a-sec7">En savoir plus &rarr;</a>

                </div>
            </div>


            <div class="item">
                <div class="single-feedback-item">
                    <img class="img-sec7" src="/assets/image/plan-geometre.webp">
                    <div style="text-align: left;">
                        <h4>Plan de Géométre</h4>


                    
                      
                    </div>
                    <p class="sec7-p">Le plan de géométrie est une représentation graphique détaillée de l'immeuble, indiquant les différentes parties communes et privatives. Il inclut également le calcul des tantièmes, qui sont des proportions utilisées pour déterminer la quote-part de chaque copropriétaire dans les charges et décisions.</p>
                    <a routerLink="/geometre" class="a-sec7">En savoir plus &rarr;</a>

                </div>
            </div>

            <div class="item">
                <div class="single-feedback-item">
                    <img class="img-sec7" src="/assets/image/dpe-collectif.webp">
                    <div style="text-align: left;">
                        <h4>DPE Collectif</h4>


                    
                      
                    </div>
                    <p class="sec7-p">Un DPE Collectif est un diagnostic de performance énergétique mené en copropriété. Il sert à évaluer la consommation d'énergie d'un immeuble résidentiel au niveau des parties communes. Il permet de prévoir les travaux de rénovation thermique pour améliorer l'efficacité énergétique du bâtiment.</p>
                    <a routerLink="/dpe" class="a-sec7">En savoir plus &rarr;</a>

                </div>
            </div>




        </div>



    </div>

    <div class="shape-circle-img2">
        <img src="assets/image/shape-img2.png" alt="image">
    </div>
</section>
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <div class="sec6-div">
            <h1 id="h1">En deux minutes et quelques clics, nous vous offrons un devis chiffré et précis !</h1>
            <ul>
            <li class="li-sec3" id="li" >
                N’hésitez pas à utiliser notre devis en ligne pour vos diagnostics obligatoires pour la vente ou la location de votre appartement ou de votre maison. Des prix en toute transparence, une prise de rendez-vous en ligne, un paiement en ligne sécurisé !
                 </li>
          
       
            </ul>
            <a routerLink="" class="uk-button uk-button-default sec6-btn">Devis gratuit en ligne !</a>

        </div>
    </div>
</section>
<!-- end section 7 -->