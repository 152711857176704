<!-- Start Page Title Area -->

<section class="funfacts-area uk-section uk-funfacts sec11">
    <div class="overlay"></div>

    <div class="uk-container">
        <div class="text-content">
            <h1 class="h1-sec11">S.A Diag</h1>
        <h5><p class="p-sec11">DIAGNOSTICS IMMOBILIERS À PARIS ET DANS TOUTE LA FRANCE</p></h5>
            <div class="button-container">
            </div>
        </div>
    </div>
</section>

<!-- End Page Title Area -->

<!-- Start Project Details Area -->
<section class="project-details-area uk-project-details uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-flex project-details">
            <div class="item uk-width-1-5" >
                <div class="project-details-info box-devis">
                    <h3>Un devis en quelques clics</h3>
                    <ul>
                     
                        <li> <span>✔️</span> Devis gratuit en ligne en 2 minutes</li>
                        <li><span>✔️</span> Prise de rendez-vous simple</li>
                        <li><span>✔️</span> Des prix en toute transparence</li>
                        
                        <a (click)="routertodevis()" class="uk-button uk-button-default btn-dev">Devis en Ligne Gratuit !</a>

                    </ul>

                </div>

            </div>
            <div class="project-details-img uk-width-expand">
                <img src="assets/image/techGlobal.webp" alt="image">
                <div class="project-details-desc">
                    <h3>Diagnostic technique global (DTG)​​</h3>
                   
        
        
        
                    <blockquote class="blockquote">
                        <p class="p-desc">Le diagnostic technique global (DTG) informe les copropriétaires sur l'état de l'immeuble. Il permet d'envisager d'éventuels travaux. Il est obligatoire pour certaines copropriétés. Si le DTG démontre que des travaux sont nécessaires, le syndic doit inscrire à l'ordre du jour de l'assemblée générale la question de faire un plan pluriannuel de travaux.
                        </p>
                    </blockquote>
        
                </div>
            </div>

          
        </div>

       

     
    </div>
</section>
<!-- End Project Details Area -->
