<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1 id="h1">Bienvenue sur la page dédiée aux diagnostics immobiliers pour les professionnels.</h1>
        <p id="p"> Notre société s'engage à fournir des services de diagnostic technique de haute qualité pour répondre aux besoins spécifiques des professionnels de l'immobilier en France. Découvrez ci-dessous nos différents services et comment nous pouvons vous accompagner dans vos projets immobiliers.</p>

        <div class="project-slides owl-carousel owl-theme">
            <div class="single-service">
                <div class="icon">
                    <img src="assets/image/Amiante.webp" class="flaticon-plan">
                </div>
                <h3>Diagnostic Amiante</h3>
            </div>
            <div class="single-service">
                <div class="icon">
                    <img src="assets/image/Surface-habitable.webp" class="flaticon-plan">
                </div>
                <h3>Diagnostic Métrage (Loi Carrez)</h3>
            </div>
            <div class="single-service">
                <div class="icon">
                    <img src="assets/image/Diagnostic-termites.webp" class="flaticon-plan">
                </div>
                <h3>Diagnostic Termites</h3>
            </div>
            <div class="single-service">
                <div class="icon">
                    <img src="assets/image/Electricite-.webp" class="flaticon-plan">
                </div>
                <h3>Diagnostic Installation électricité</h3>
            </div>
            <div class="single-service">
                <div class="icon">
                    <img src="assets/image/Gaz.webp" class="flaticon-plan">
                </div>
                <h3>Diagnostic Installation gaz</h3>
            </div>
            <div class="single-service">
                <div class="icon">
                    <img src="assets/image/Plomb.webp" class="flaticon-plan">
                </div>
                <h3>Diagnostic Plomb(Crep)</h3>
            </div>
            <div class="single-service">
                <div class="icon">
                    <img src="assets/image/Surface-habitable.webp" class="flaticon-plan">
                </div>
                <h3>Diagnostic Méterage (Loi Boutin)</h3>
            </div>
            <div class="single-service">
                <div class="icon">
                    <img src="assets/image/ERP.webp" class="flaticon-plan">
                </div>
                <h3>Diagnostic risques et pollutions(ERP) </h3>
            </div>
            <div class="single-service">
                <div class="icon">
                    <img src="assets/image/Installation-assainissement-non-collectif.webp" class="flaticon-plan">
                </div>
                <h3>Diagnostic Assainissement</h3>
            </div>
            <div class="single-service">
                <div class="icon">
                    <img src="assets/image/dtg.webp" class="flaticon-plan">
                </div>
                <h3>Diagnostic techinque global(DTG)</h3>
            </div>
            <div class="single-service">
                <div class="icon">
                    <img src="assets/image/audit-01.webp" class="flaticon-plan">
                </div>
                <h3>Autid énergetique</h3>
            </div>
            <div class="single-service">
                <div class="icon">
                    <img src="assets/image/partie-commune-01.webp" class="flaticon-plan">
                </div>
                <h3>Diagnostic parties commues</h3>
            </div>
            <div class="single-service">
                <div class="icon">
                    <img src="assets/image/plan-geometre.webp" class="flaticon-plan">
                </div>
                <h3>Plan de Géométre</h3>
            </div>
            <div class="single-service">
                <div class="icon">
                    <img src="assets/image/dpe-collectif.webp" class="flaticon-plan">
                </div>
                <h3>DPE Collectif</h3>
            </div>
        </div>
    </div>
</section>


<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="uk-about about-area uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <h2>Syndic-Immeuble en copropriété</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text">
                        <div class="icon">
                        </div>
                        <p id="Syndic">Dans le cadre de la gestion des 
                            immeubles en copropriété, il est essentiel de 
                            disposer d'évaluations techniques précises pour
                             assurer leur bon fonctionnement et la sécurité des
                              résidents. Notre société de diagnostic immobilier 
                              offre des services spécialisés adaptés aux besoins 
                              des syndics et gestionnaires d'immeubles. Découvrez 
                              ci-dessous les différents diagnostics que nous proposons
                               pour accompagner efficacement
                             la gestion et la maintenance de vos biens immobiliers collectifs.</p>
                        <div class="signature">
                            <a (click)="sendphone()"  class="uk-button uk-button-default">Devis gratuit en ligne !</a>
                        </div>
                    </div>
                </div>
            </div>

         
        </div>
    </div>
</section>
<!-- End About Area -->
<section id="About" class="uk-features  features-area">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m">
            <div class="uk-item">
                <div class="single-features-box">
               
                    <h3>DTG (Diagnostic Technique Global)</h3>
                    <div class="bar"></div>
                    <p>Le DTG est un outil essentiel pour les syndics et les gestionnaires d'immeubles en copropriété. Il permet d'évaluer l'état technique d'un immeuble et d'anticiper les travaux nécessaires. Notre équipe de professionnels qualifiés
                         peut vous assister dans la réalisation de ce diagnostic.</p>

                

                    <div class="animation-img">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                    </div>
                </div>
            </div>

        
            <div class="uk-item">
                <div class="single-features-box">
                 
                    <h3>DPE Collectif</h3>
                    <div class="bar"></div>
                    <p>Le Diagnostic de Performance Energétique 
                        collectif est indispensable pour les bâtiments en
                         copropriété. Il fournit une estimation de la consommation 
                         énergétique globale de l'immeuble. Nous sommes là pour vous
                          aider à réaliser cette évaluation dans le respect des normes
                           en vigueur.</p>


                    <div class="animation-img">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                    </div>
                </div>
            </div>
            
            <div class="uk-item">
                <div class="single-features-box">

                    <h3>DTA (Dossier Technique Amiante)</h3>
                    <div class="bar"></div>
                    <p>Le DTA est obligatoire pour les immeubles
                         construits avant 1997. Il vise à repérer la présence
                          d'amiante dans les parties communes. Nos experts peuvent
                           vous accompagner dans l'établissement
                         de ce dossier conformément à la réglementation.</p>

                

                    <div class="animation-img">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</section>
<section id="About2" class="uk-features  features-area">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m">
            <div class="uk-item">
                <div class="single-features-box">
               
                    <h3>Termites Parties Communes</h3>
                    <div class="bar"></div>
                    <p>La présence de termites peut compromettre la structure d'un immeuble. Nous proposons des diagnostics précis pour détecter la présence
                         de ces insectes nuisibles dans les parties communes.</p>

                

                    <div class="animation-img">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                    </div>
                </div>
            </div>

        
            <div class="uk-item">
                <div class="single-features-box">
                 
                    <h3>Plomb Parties Communes</h3>
                    <div class="bar"></div>
                    <p>La recherche de plomb dans
                         les peintures est obligatoire pour les immeubles 
                         construits avant 1949. Nous offrons des services de diagnostic fiables
                         pour évaluer la présence de plomb dans les parties communes.</p>


                    <div class="animation-img">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                    </div>
                </div>
            </div>
            
            <div class="uk-item">
                <div class="single-features-box">

                    <h3>Assainissement</h3>
                    <div class="bar"></div>
                    <p>Un système d'assainissement
                         défectueux peut entraîner des problèmes
                          sanitaires et environnementaux. Nous réalisons
                           des diagnostics complets pour évaluer l'état de 
                        l'assainissement dans les immeubles en copropriété.</p>

                

                    <div class="animation-img">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</section>
<!-- Start About Area -->
<section id="uk-about" class="uk-about about-area uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <h2 id="Syndic2">Locaux Professionnels</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text">
                        <div class="icon">
                        </div>
                        <p id="Syndic2">Notre expertise ne se limite 
                            pas aux immeubles en copropriété. Nous proposons
                             également une gamme complète de services de diagnostic
                              pour les locaux professionnels, afin de garantir 
                            la conformité réglementaire et la sécurité des occupants.</p>
                        <div class="signature">
                            <a (click)="sendphone()"  class="uk-button uk-button-default">Devis gratuit en ligne !</a>
                        </div>
                    </div>
                </div>
            </div>

         
        </div>
    </div>
</section>
<section id="About3" class="feedback-section uk-process uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-text-center">
            <span>Testimonials</span>
            <h2>We have a great review from our clients</h2>
       
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-feedback-item">
                   
                    <p>Le Diagnostic de Performance Energétique est un outil
                         essentiel pour évaluer la performance énergétique 
                         des locaux professionnels. Nous vous accompagnons dans
                         la réalisation de ce diagnostic pour
                         optimiser l'efficacité énergétique de vos bâtiments.</p>
                    <div class="client-info">
                    
                        <h3>DPE</h3>
                        
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="single-feedback-item">
                   
                    <p>Le Dossier Technique Amiante est également crucial pour les locaux professionnels. Nous mettons à votre disposition notre expertise pour identifier la présence d'amiante dans vos locaux et établir un dossier conforme à la réglementation.</p>
                    <div class="client-info">
                    
                        <h3>DTA</h3>
                        
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="single-feedback-item">
                   
                    <p>Nos services de diagnostic couvrent également la recherche de plomb, de termites et d'autres parasites dans les locaux professionnels. Nous vous aidons à identifier et à traiter ces risques potentiels pour garantir la sécurité des occupants.</p>
                    <div class="client-info">
                    
                        <h3 
        
                        >Plomb, Termites, Etat parasitaires</h3>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-feedback-item">
                   
                    <p>L'assainissement des locaux professionnels est un enjeu majeur pour la santé et le bien-être des occupants. Nous proposons des diagnostics approfondis pour évaluer l'état de l'assainissement et recommander des mesures correctives si nécessaire.</p>
                    <div class="client-info">
                    
                        <h3>Assainissement</h3>
                        
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="single-feedback-item">
                   
                    <p>L'audit énergétique est un outil précieux pour identifier les sources de gaspillage énergétique et mettre en place des solutions d'amélioration. Nous réalisons des audits énergétiques complets pour vous aider à réduire vos coûts énergétiques et votre empreinte environnementale.</p>
                    <div class="client-info">
                    
                        <h3>Audit énergétique</h3>
                        
                    </div>
                </div>
            </div>

      
        </div>
    </div>

    <div class="shape-circle-img2">
        <img src="assets/img/shape-img2.png" alt="image">
    </div>
</section>
<section class="uk-about about-area uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <h2>Diagnostic Travaux</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text">
                        <div class="icon">
                        </div>
                        <p id="Syndic">Dans le cadre de projets de rénovation
                            ou de construction, nos services de diagnostic avant et après travaux 
                           garantissent la sécurité et la conformité de vos chantiers.</p>
                        <div class="signature">
                            <a (click)="sendphone()" class="uk-button uk-button-default">Devis gratuit en ligne !</a>
                        </div>
                    </div>
                </div>
            </div>

         
        </div>
    </div>
</section>
<section id="About6" class="uk-features  features-area">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m">
            <div class="uk-item">
                <div class="single-features-box">
               
                    <h3>Amiante avant travaux</h3>
                    <div class="bar"></div>
                    <p>Avant d'entreprendre des travaux de rénovation, 
                        il est essentiel de réaliser un diagnostic amiante pour identifier
                         et traiter les matériaux contenant de l'amiante. Nous vous accompagnons
                          dans cette démarche pour
                         assurer la sécurité des travailleurs et des occupants.</p>

                

                    <div class="animation-img">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                    </div>
                </div>
            </div>

        
            <div class="uk-item">
                <div class="single-features-box">
                 
                    <h3>Plomb avant Travaux</h3>
                    <div class="bar"></div>
                    <p>La recherche de plomb avant travaux 
                        est une étape cruciale pour éviter toute 
                        exposition dangereuse. Nous effectuons des 
                        diagnostics précis pour repérer la présence de
                         plomb dans les matériaux avant le début des travaux.</p>


                    <div class="animation-img">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                    </div>
                </div>
            </div>
            
            <div class="uk-item">
                <div class="single-features-box">

                    <h3>Contrôle Amiante après Travaux</h3>
                    <div class="bar"></div>
                    <p>Après des travaux de rénovation 
                        ou de démolition, il est important de vérifier 
                        la présence éventuelle de résidus d'amiante dans l'environnement. 
                        Nous réalisons des contrôles après
                         travaux pour garantir la sécurité des lieux.</p>

                

                    <div class="animation-img">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</section>
<section id="About4" class="uk-features  features-area">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m">
            <div class="uk-item">
                <div class="single-features-box">
               
                    <h3>Contrôle Plomb après Travaux</h3>
                    <div class="bar"></div>
                    <p>Nous proposons des contrôles
                         après travaux pour vérifier la conformité des locaux vis-à-vis
                         du plomb, après des opérations de rénovation ou de démolition</p>

                

                    <div class="animation-img">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                    </div>
                </div>
            </div>

        
            <div class="uk-item">
                <div class="single-features-box">
                 
                    <h3>Amiante voirie</h3>
                    <div class="bar"></div>
                    <p>Nous proposons des diagnostics spécifiques pour les voiries afin de détecter la 
                        présence éventuelle d'amiante dans les revêtements routiers.</p>


                    <div class="animation-img">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape2.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                        <img src="assets/img/shape1.svg" alt="image">
                        <img src="assets/img/shape3.svg" alt="image">
                    </div>
                </div>
            </div>
            
        
        </div>
    </div>
    
</section>
<!-- Start Feedback Area -->

<!-- End Feedback Area -->

<!-- Start Partner Area -->

<!-- End Partner Area -->


<!-- End Team Area -->

<!-- Start Subscribe Area -->

    <div class="shape"><img src="assets/img/footer-shape1.png" alt="shape"></div>

<!-- End Subscribe Area -->
