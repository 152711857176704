<!-- Start Page Title Area -->

<section class="funfacts-area uk-section uk-funfacts sec11">
    <div class="overlay"></div>

    <div class="uk-container">
        <div class="text-content">
            <h1 class="h1-sec11">S.A Diag</h1>
            <h5><p class="p-sec11">DIAGNOSTICS IMMOBILIERS À PARIS ET DANS TOUTE LA FRANCE</p></h5>
            <div class="button-container">
            </div>
        </div>
    </div>
</section>

<!-- End Page Title Area -->

<!-- Start Project Details Area -->
<section class="project-details-area uk-project-details uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-flex project-details">
            <div class="item uk-width-1-5" >
                <div class="project-details-info box-devis">
                    <h3>Un devis en quelques clics</h3>
                    <ul>
                     
                        <li> <span>✔️</span> Devis gratuit en ligne en 2 minutes</li>
                        <li><span>✔️</span> Prise de rendez-vous simple</li>
                        <li><span>✔️</span> Des prix en toute transparence</li>
                        
                        <a (click)="routertodevis()" class="uk-button uk-button-default btn-dev">Devis en Ligne Gratuit !</a>

                    </ul>

                </div>

            </div>
            <div class="project-details-img uk-width-expand">
                <img src="assets/image/diag_termites.webp" alt="image">
                <div class="project-details-desc">
                    <h3>Diagnostic termites​​</h3>
                    <span class="spn1">+  <span class="spn2">Vente : obligatoire </span></span><br>
                    <span class="spn1">+  <span class="spn2">Location : optionnel</span></span><br>
                    <span class="spn1">+  <span class="spn2">Durée de validité : 6 mois</span></span>
        
        
        
        
                    <blockquote class="blockquote">
                        <p class="p-desc">L'état relatif à la présence de termites, également appelé diagnostic termites, donne des informations sur la présence ou non d'insectes xylophages (termites : Insectes qui rongent le bois et les matériaux contenant de la cellulose en particulier) dans un logement. Ce document doit être réalisé lorsque le logement est situé dans une zone déclarée par un arrêté préfectoral. Il doit être remis au candidat acquéreur.
                        </p>
                    </blockquote>
        
                </div>
            </div>

          
        </div>

       

     
    </div>
</section>
<!-- End Project Details Area -->
