<!-- Start Page Title Area -->

<section class="funfacts-area uk-section uk-funfacts sec11">
    <div class="overlay"></div>

    <div class="uk-container">
        <div class="text-content">
            <h1 class="h1-sec11">S.A Diag</h1>
            <p class="p-sec11">DIAGNOSTICS IMMOBILIERS À PARIS ET DANS TOUTE LA FRANCE</p>
            <div class="button-container">
            </div>
        </div>
    </div>
</section>

<!-- End Page Title Area -->

<!-- Start Project Details Area -->
<section class="project-details-area uk-project-details uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-flex project-details">
            <div class="item uk-width-1-5" >
                <div class="project-details-info box-devis">
                    <h3>Un devis en quelques clics</h3>
                    <ul>
                     
                        <li> <span>✔️</span> Devis gratuit en ligne en 2 minutes</li>
                        <li><span>✔️</span> Prise de rendez-vous simple</li>
                        <li><span>✔️</span> Des prix en toute transparence</li>
                        
                        <a (click)="routertodevis()" class="uk-button uk-button-default btn-dev">Devis en Ligne Gratuit !</a>

                    </ul>

                </div>

            </div>
            <div class="project-details-img uk-width-expand">
                <img src="assets/image/Amiante_desc.webp" alt="image">
                <div class="project-details-desc">
                    <h3>Amiante​</h3>
                    <span class="spn1">+  <span class="spn2">Vente : obligatoire pour les constructions avant 1997</span></span><br>
                    <span class="spn1">+  <span class="spn2">Location : obligatoire pour les constructions avant 1997</span></span><br>
                    <span class="spn1">+  <span class="spn2">Durée de validité : Illimité si abscence d'amiante. Il est conseillé de réaliser un diagnostic amiante avant chaque vente.</span></span>
        
        
        
        
                    <blockquote class="blockquote">
                        <p class="p-desc">L'état d'amiante, aussi appelé diagnostic amiante, est un document qui mentionne la présence ou l'absence de matériaux ou produits contenant de l'amiante dans un logement. Votre logement est concerné par ce diagnostic si son permis de construire a été délivré avant juillet 1997. L'état d'amiante doit être intégré au dossier de diagnostic technique (DDT). Le DDT doit être remis à l'acquéreur en cas de vente d'un logement.​</p>
                    </blockquote>
        
                </div>
            </div>

          
        </div>

       

     
    </div>
</section>
<!-- End Project Details Area -->
