<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
      <h1>Contactez-nous</h1>
      <ul>
      </ul>
    </div>
  </section>
  
  <!-- Start Project Area -->
  <section id="project" class="project-area uk-project uk-section">
    <div class="uk-container">
      <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
        <div class="form-container">
          <div class="alert-box">
            <span>Précisez l'objet de votre demande et nous y répondrons dans les plus brefs délais.</span>
          </div>
          
          <form class="contact-form" [formGroup]="Formrappel" (ngSubmit)="onSubmit()">
            <h2>Contactez-nous</h2>
  
            <label for="projet">Quelle est votre projet</label>
            <select id="projet" formControlName="projet" required>
              <option value="" disabled selected></option>
              <option value="Location">Location</option>
              <option value="Vente">Vente</option>
              <option value="Audit énergétique">Audit énergétique</option>
              <option value="Parties communes">Parties communes</option>
              <option value="Demande particulière">Demande particulière</option>
            </select>
            <div *ngIf="formControls.projet.invalid && (formControls.projet.dirty || formControls.projet.touched)">
              <small *ngIf="formControls.projet.errors?.['required']">
                Le projet est requis.</small>
            </div>
  
            <label for="type-bien">Quel est le type du bien ?</label>
            <select id="type-bien" formControlName="typeBien" required>
              <option value="" disabled selected></option>
              <option value="Appartement">Appartement</option>
              <option value="Maison">Maison</option>
              <option value="Local commercial">Local commercial</option>
              <option value="Local industriel">Local industriel</option>
              <option value="Parking">Parking</option>
              <option value="Cave">Cave</option>
              <option value="Terrain">Terrain</option>
            </select>
            <div *ngIf="formControls.typeBien.invalid && (formControls.typeBien.dirty || formControls.typeBien.touched)">
              <small *ngIf="formControls.typeBien.errors?.['required']">Type de bien est requis.</small>
            </div>
  
            <label for="message">Message</label>
            <textarea id="message" formControlName="message" rows="4" placeholder="Saisir votre message" required></textarea>
            <div *ngIf="formControls.message.invalid && (formControls.message.dirty || formControls.message.touched)">
              <small *ngIf="formControls.message.errors?.['required']">Message est requis.</small>
            </div>
  
            <h3>Vos coordonnées</h3>
            <div id="cordonee">
              <div class="form-row">
                <div class="input-group">
                  <label for="prenom">Prénom</label>
                  <input type="text" id="prenom" formControlName="prenom" placeholder="Saisir votre prénom" required>
                  <div *ngIf="formControls.prenom.invalid && (formControls.prenom.dirty || formControls.prenom.touched)">
                    <small *ngIf="formControls.prenom.errors?.['required']">Prénom est requis..</small>
                  </div>
                </div>
  
                <div class="input-group">
                  <label for="nom">Nom</label>
                  <input type="text" id="nom" formControlName="nom" placeholder="Saisir votre nom" required>
                  <div *ngIf="formControls.nom.invalid && (formControls.nom.dirty || formControls.nom.touched)">
                    <small *ngIf="formControls.nom.errors?.['required']">Nom est requis..</small>
                  </div>
                </div>
              </div>
  
              <div class="form-row">
                <div class="input-group">
                  <label for="email">Email</label>
                  <input type="email" id="email" formControlName="email" placeholder="Saisir votre email" required>
                  <div *ngIf="formControls.email.invalid && (formControls.email.dirty || formControls.email.touched)">
                    <small *ngIf="formControls.email.errors?.['required']">Email est requis..</small>
                    <small *ngIf="formControls.email.errors?.['email']">Please enter a valid email address.</small>
                  </div>
                </div>
  
                <div class="input-group">
                  <label for="telephone">Téléphone</label>
                  <input type="number" id="telephone" formControlName="telephone" placeholder="Saisir votre téléphone" required>
                  <div *ngIf="formControls.telephone.invalid && (formControls.telephone.dirty || formControls.telephone.touched)">
                    <small *ngIf="formControls.telephone.errors?.['required']">Téléphone est requis..</small>
                  </div>
                </div>
              </div>
            </div>
            <div class="objective-buttonse" >
              <a class="objective-button" [ngClass]="{'active': selectedObjectives === 'Oui'}" (click)="setActive('Oui')">
                  OUI <span *ngIf="selectedObjectives === 'Oui'" class="check-icon">✔</span>
              </a>
              <a class="objective-button" [ngClass]="{'active': selectedObjectives=== 'Non'}" (click)="setActive('Non')">
                  NON <span *ngIf="selectedObjectives=== 'Non'" class="check-icon">✔</span>
              </a>
              </div>
            <div *ngIf="selectedObjectives=== 'Oui'">
              <div id="pre"> <label>Société</label>
               <div id="cordoneer" >
               <form [formGroup]="Formrappel" id="contactForm">
                   <div class="form-row">
                     <div class="input-group">
                       <label for="prenom">Nom de votre société (intermédiaire)</label>
                       <input type="text" placeholder="Saisir le nom  de votre société" class="uk-input">                                 
                     </div>
                   </div>
       </form>
          </div>
          </div>
      
               </div>
            <a [ngClass]="{'disabled-link': Formrappel.invalid}" id="env"(click)="sendMail()" class="uk-button2 uk-button-default">Envoyer</a>
          </form>
        </div>
      </div>
    </div>
  </section>
  